import {
    Box,
    Button,
    Container, createTheme,
    Paper, TextField, ThemeProvider
} from "@mui/material";
import { observer } from "mobx-react";
import React, { useEffect, useState, type FC } from "react";
import { type Image2QRStore } from "../store/Image2QRStore";
// import { isMobileContext } from "src/contexts/rootContext";
import { BallTriangle } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import AspectRatioBox from "src/components/aspectRatioBox";
import { getCognitoIdToken } from "src/utils/tokenUtils";

interface IAddURLPageProps {
    store: Image2QRStore;
}

export const AddURLPage:FC<IAddURLPageProps> = observer(({ store }:IAddURLPageProps) => {
    const lightTheme = createTheme({
        palette: {
        mode: "light",
        primary: {
            light: "#81c8e2",
            main: "#51c8e2",
            dark: "#21b8de"
        }
        }
    });
    const navigate = useNavigate();
    // const isMobile = useContext(isMobileContext);
    // console.log("store1base64Image", store.base64EncodedImage);

    const handleFinishClick = async () => {
        await store.postQRInfo().then(() => {
            if (getCognitoIdToken()) {
                navigate('/managementPortal');
                window.scrollTo(0, 0);
            }
        }).catch((e) => {
            if (e.response.status === 400) {
                store.rootStore.limitReachedModalMessage = e.response.data;
                store.rootStore.showLimitReachedModal = true;
            }
            console.log("Error ", JSON.stringify(e.response));
        });
    }
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        store.qrInfo.qrID = sessionStorage.getItem("the_qr_store_qr_id") ?? "";
        store.getQRInfo()
        .then(() => {
          console.log("qrInfoVal " + JSON.stringify(store.qrInfo));
          setIsLoading(false);
        })
        .catch((e) => { console.log(e) });
      }, []);

    return (
            <Box sx={{ backgroundColor: "#fafafa", paddingBottom: 10 }}>
                {
                isLoading &&
                <Box minHeight={"80vh"} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <Box width={"50%"} maxWidth={300}>
                <BallTriangle
                    height={"100%"}
                    width={"100%"}
                    radius={"8%"}
                    color="#0099aa"
                    visible={true}
                    />
                </Box>
                </Box>
            }
                {
                !isLoading &&
                <Container maxWidth={"sm"} sx={{ width: 7 / 8, paddingTop: 2, paddingBottom: 2 }}>
                <ThemeProvider theme={lightTheme}>
                <Paper>
                    <Container sx={{ boxShadow: 2, marginBottom: 2 }}>
                        <AspectRatioBox ratio={1}>
                            <img src={store.rootStore.stageConfig.qrImageURLBase + (store.qrInfo.qrImageURL ?? "")} width={"100%"} placeholder=""/>
                        </AspectRatioBox>
                    </Container>
                    <Box paddingX={3}>
                        <TextField
                        fullWidth
                        placeholder="https://theqrstore.in"
                        label="QR Title"
                        variant="outlined"
                        color="primary"
                        margin="normal"
                        value={store.qrInfo.qrName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { store.setQRName(e.target.value); }}
                        />
                        <TextField
                        fullWidth
                        placeholder="https://theqrstore.in"
                        label="Your Website URL"
                        variant="outlined"
                        color="primary"
                        margin="normal"
                        value={store.qrInfo.websiteEndpoint}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { store.setWebsiteEndpoint(e.target.value); }}
                        />
                        <TextField
                        fullWidth
                        placeholder="https://theqrstore.in"
                        label="Description (Optional)"
                        variant="outlined"
                        color="primary"
                        margin="normal"
                        value={store.qrInfo.qrDescription ?? ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { store.setQRDescription(e.target.value); }}
                        />
                    </Box>
                    <Button
                    variant="outlined"
                    onClick={() => { void handleFinishClick(); }}
                    sx={{ marginBottom: 2 }}
                    >
                    Finish
                    </Button>
                    </Paper>
                </ThemeProvider>
                </Container>
                }
            </Box>
        )
    }
)