import { createTheme, responsiveFontSizes } from "@mui/material";

let redTheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        light: "#ff7070",
        main: "#ee4040",
        dark: "#cc0000"
      }
    },
    typography: {
      fontFamily: "'Lora', serif"
    }
  });
redTheme = responsiveFontSizes(redTheme);

export { redTheme };
