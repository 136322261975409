import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React, { useContext, useState, type FC } from "react";
import { useSearchParams } from "react-router-dom";
import { isMobileContext } from "src/contexts/rootContext";
import { type RootStore } from "src/store/RootStore";

interface IContactUsPageProps {
    store: RootStore;
}

// interface ExpandMoreProps extends IconButtonProps {
//     expand: boolean;
//   }

export const ContactUsPage:FC<IContactUsPageProps> = observer(({ store }:IContactUsPageProps) => {
    console.log(store.isLoggedIn)
    const isMobile = useContext(isMobileContext);
    const [searchParams] = useSearchParams();
    const [isVal, setIsVal] = useState<number>(Number(searchParams.get("page") ?? 0));
    return (
        <Box>
            <Box display={"flex"} justifyContent={"space-around"} alignItems={"center"} sx={{ backgroundColor: "#d0dfda" }}>
                {
                    isVal === 0 &&
                    <div style={{ backgroundColor: "#ffffff", cursor: "pointer", borderTopLeftRadius: 15, borderTopRightRadius: 15, padding: 1, paddingLeft: isMobile ? 5 : 10, paddingRight: isMobile ? 5 : 10 }}>
                        <Typography variant= { isMobile ? "h6" : "h5" }>
                            Video2QR
                        </Typography>
                    </div>
                }
                {
                    isVal !== 0 &&
                    <div onClick={() => { setIsVal(0) }} style={{ cursor: "pointer", padding: 1, paddingLeft: isMobile ? 5 : 10, paddingRight: isMobile ? 5 : 10 }}>
                        <Typography variant= { isMobile ? "h6" : "h5" }>
                            Video2QR
                        </Typography>
                    </div>
                }
                {
                    isVal === 1 &&
                    <div style={{ backgroundColor: "#ffeeee", cursor: "pointer", borderTopLeftRadius: 15, borderTopRightRadius: 15, padding: 1, paddingLeft: isMobile ? 5 : 10, paddingRight: isMobile ? 5 : 10 }}>
                        <Typography variant= { isMobile ? "h6" : "h5" }>
                            API access
                        </Typography>
                    </div>
                }
                {
                    isVal !== 1 &&
                    <div onClick={() => { setIsVal(1) }} style={{ cursor: "pointer", paddingLeft: isMobile ? 5 : 10, padding: 1, paddingRight: isMobile ? 5 : 10 }}>
                        <Typography variant= { isMobile ? "h6" : "h5" }>
                            API access
                        </Typography>
                    </div>
                }
                {
                    isVal === 2 &&
                    <div style={{ backgroundColor: "#ffffff", cursor: "pointer", borderTopLeftRadius: 15, borderTopRightRadius: 15, padding: 1, paddingLeft: isMobile ? 5 : 10, paddingRight: isMobile ? 5 : 10 }}>
                        <Typography variant= { isMobile ? "h6" : "h5" }>
                            General
                        </Typography>
                    </div>
                }
                {
                    isVal !== 2 &&
                    <div onClick={() => { setIsVal(2) }} style={{ cursor: "pointer", paddingLeft: isMobile ? 5 : 10, padding: 1, paddingRight: isMobile ? 5 : 10 }}>
                        <Typography variant= { isMobile ? "h6" : "h5" }>
                            General
                        </Typography>
                    </div>
                }
            </Box>
            {
                isVal === 0 &&
                <iframe allow="camera" style={{ height: "100vh", width: "100%", border: "none" }} src='https://forms.zohopublic.in/theqrstore/form/ContactUs/formperma/Wc1FRCg6idP9b3U_-qggE2wQA8rQI-7xS9q1jFZAbGg'></iframe>
            }
            {
                isVal === 1 &&
                <iframe style={{ height: "100vh", width: "100%", border: "none" }} src='https://forms.zohopublic.in/theqrstore/form/ContactUs1/formperma/AQy_mhOZAKRSQmZ9BclqTGS_JMuPEB7M4wwhOxYFPHM'></iframe>
            }
            {
                isVal === 2 &&
                <iframe style={{ height: "100vh", width: "100%", border: "none" }} src='https://forms.zohopublic.in/theqrstore/form/ContactUs2/formperma/XXlLT56VeqW40v-FZBKijBbaxWthKABhTOgh8dujzms'></iframe>
            }
        </Box>
    )
})