import { Box, Button, ButtonBase, Card, CardActions, CardContent, CardMedia, Link, TextField, ThemeProvider, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React, { useContext, useEffect, useState, type FC } from "react";
import { BallTriangle } from "react-loader-spinner";
import { useNavigate } from 'react-router-dom';
import { LimitReachedPopup } from "src/components/LimitReachedPopup";
import { QRInfoCard } from 'src/components/QRInfoCard';
import { TemplateCard } from 'src/components/TemplateCard';
import { IMAGE_URI_BASE } from 'src/constants/RootConstants';
import { isMobileContext, isTabContext } from 'src/contexts/rootContext';
import { type RootStore } from "src/store/RootStore";
import { redTheme } from 'src/themes';
import { type QRInfo } from 'src/types/QRInfoTypes';

interface IQRManagementPageProps {
    store: RootStore;
}

// interface ExpandMoreProps extends IconButtonProps {
//     expand: boolean;
//   }

export const QRManagementPage:FC<IQRManagementPageProps> = observer(({ store }:IQRManagementPageProps) => {
    console.log(store.isLoggedIn)
    const isMobile = useContext(isMobileContext);
    const isTab = useContext(isTabContext);
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [templateName, setTemplateName] = useState<string>("");
    // const [isNameEdit, setIsNameEdit] = useState<boolean>(false);
    const [isSelected, setIsSelected] = useState<number>(0);
    const [addClicked, setAddClicked] = useState<boolean>(false);
    const [webpageLink, setWebpageLink] = useState<string>("https://theqrstore.in");
    const [qrName, setQRName] = useState<string>("BeautifulQR");
    const [qrDescription, setQRDescription] = useState<string>("");
    const navigate = useNavigate();
    useEffect(() => {
        console.log("In getQRInfo")
        store.getCustomerQRInfo().then(() => { setIsLoaded(true); setTemplateName(store.customerQRInfo.qrList[0].templateName); }).catch((e) => { console.log("Error Occured", e) });
    }, [])
    const templateClick = (index: number) => {
        setIsSelected(index)
        // setIsNameEdit(false)
        setTemplateName(store.customerQRInfo.qrList[index].templateName)
    }
    const handleNextClick = () => {
        setIsLoaded(false);
        const qrInfo: QRInfo = JSON.parse(JSON.stringify(store.customerQRInfo.qrList[isSelected].qrInfo[0]));
        qrInfo.qrName = qrName;
        qrInfo.qrDescription = qrDescription;
        qrInfo.websiteEndpoint = webpageLink;
        store.postQRFromTemplate(qrInfo).then(() => {
            setAddClicked(false);
            setIsSelected(0);
            setTemplateName(store.customerQRInfo.qrList[0].templateName);
            setWebpageLink("https://theqrstore.in");
            setQRName("BeautifulQR");
            setQRDescription("");
            setIsLoaded(true);
        }).catch((error: any) => {
            console.log(error)
            setAddClicked(false);
            setWebpageLink("https://theqrstore.in");
            setQRName("BeautifulQR");
            setQRDescription("");
            setIsLoaded(true);
        })
    }
    const handleCancelClick = () => {
        setAddClicked(false);
        setWebpageLink("https://theqrstore.in");
        setQRName("BeautifulQR");
        setQRDescription("");
    }
    const handleDeleteClick = (index: number) => {
        setIsLoaded(false);
        store.deleteCustomerQR(store.customerQRInfo.qrList[isSelected].qrInfo[index].qrID).then(() => {
            if (isSelected === store.customerQRInfo.qrList.length && store.customerQRInfo.qrList.length !== 0) { setIsSelected(store.customerQRInfo.qrList.length - 1); setTemplateName(store.customerQRInfo.qrList[store.customerQRInfo.qrList.length - 1].templateName); }
            setIsLoaded(true);
        }).catch((error) => {
            console.log(error)
        })
    }

    const handleAddQRClick = () => {
        if (store.customerQRInfo.noOfActiveQR === store.customerQRInfo.maxNoOfActiveQR) {
            store.showLimitReachedModal = true;
        } else {
            setAddClicked(true);
        }
    }

    const handleAddTemplateClick = () => {
        if (store.customerQRInfo.noOfActiveQR === store.customerQRInfo.maxNoOfActiveQR) {
            store.showLimitReachedModal = true;
        } else {
            navigate("/qrgenworkflow/1");
            window.scrollTo(0, 0);
        }
    }

    return (
        <Box margin={2}>
        <Typography variant="h4" marginBottom={1} marginLeft={1} >
            QR Management Platform
        </Typography>
        <Typography variant= {isTab ? isMobile ? "body2" : "body1" : "h6"} color="text.secondary" marginBottom={3} marginLeft={1} textAlign={"center"} >
            Generate, Modify, and Manage Dynamic QR Codes with Ease!
        </Typography>
        {
            !isLoaded &&
            <Box minHeight={"80vh"} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Box width={"50%"} maxWidth={300}>
            <BallTriangle
                height={"100%"}
                width={"100%"}
                radius={"8%"}
                color="#0099aa"
                visible={true}
                />
            </Box>
            </Box>
        }
        {
            isLoaded &&
            <Box>
                {
                    store.customerQRInfo.qrList.length === 0 &&
                    <Box>
                        <Typography textAlign={"left"} marginLeft={3} marginBottom={1} color={"red"}>
                            You dont have any active QRs or templates.  Click add new to create one.
                        </Typography>
                    </Box>
                }
                <Box sx={{ display: "flex", marginLeft: 1 }}>
                <Box sx={{ display: "flex", overflowX: "scroll", '&::-webkit-scrollbar': { width: 0 } }}>
                { store.customerQRInfo.qrList.map((templateInfo, index) => (
                    <TemplateCard store = {store} key={templateInfo.templateID} templateInfo={templateInfo} onClick={() => { templateClick(index); }}/>
                ))}
                </Box>
                <ButtonBase onClick={ handleAddTemplateClick } sx={{ display: "flex", flexDirection: "column", marginBottom: 4 }}>
                    <Card sx={{ width: isMobile ? 50 : 90, minWidth: isMobile ? 50 : 90, boxShadow: 6, flexGrow: 1 }}>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography variant= {isMobile ? "body2" : "body1" } marginTop={1} marginBottom={isMobile ? 2 : 4 } marginLeft={1} >
                                Add New
                            </Typography>
                            <CardMedia
                                component="img"
                                image = {IMAGE_URI_BASE + "darkPlus.jpg"}
                                crossOrigin='anonymous'
                                alt="Paella dish"
                                sx={{ width: "100%", margin: "auto", aspectRatio: 0.5 }}
                            />
                        </Box>
                    </Card>
                </ButtonBase>
                </Box>
                {
                    store.customerQRInfo.qrList.length > 0 &&
                    <Card sx={{ width: isMobile ? "90%" : "80%", margin: "auto", boxShadow: 6, marginBottom: 2 }}>
                    <Box>
                    {/* <Collapse in={expanded} timeout="auto" collapsedSize={"50%"} > */}
                        {/* <CardHeader
                            // avatar={
                            // <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                            //     R
                            // </Avatar>
                            // }
                            action={
                            <Box>
                            </Box>
                            }
                            title="Template: avgGtdhh15_"
                            subheader="No. of QRs: 1"
                        /> */}
                        {/* <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={10} sx={{ marginTop: 1 }}>
                        {
                            isNameEdit &&
                            <Box sx = {{ marginTop: 2, marginRight: -5 }}>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <TextField
                                variant='standard'
                                value={templateName}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setTemplateName(event.target.value);
                                }}
                                sx={{ width: "50%", marginRight: 1, textAlign: "center" }}/>
                                <Button variant='outlined' color="secondary"
                                onClick={() => { setIsNameEdit(false); }}>
                                    Save
                                </Button>
                                </Box>
                                <Typography variant="body2" color="text.secondary" paddingBottom={3}>
                                    { "No. of QRs:" + String(store.customerQRInfo.qrList[isSelected].qrInfo.length) }
                                </Typography>
                            </Box>
                        }
                        {
                            !isNameEdit && */}
                            <Box> {/* sx={{ marginRight: isTab ? 0 : -15 }} */}
                                <Box sx={{ marginTop: 2, display: "flex", justifyContent: "center" }}>
                                <Typography variant={"h5"} sx={{ marginRight: 1 }}>
                                    {templateName}
                                </Typography>
                                {/* <IconButton sx={{ marginTop: -1 }} onClick={() => { setIsNameEdit(true) }}>
                                    <EditIcon fontSize={ isMobile ? 'small' : 'medium' }/>
                                </IconButton> */}
                                </Box>
                                <Typography variant="body2" color="text.secondary" >
                                    { "No. of QRs : " + String(store.customerQRInfo.qrList[isSelected].qrInfo.length) }
                                </Typography>
                            </Box>
                        {/* // }
                        // </Grid>
                        // <Grid item xs={6} sm={6} md={2}>
                        // <IconButton>
                        //     <DownloadIcon />
                        // </IconButton>
                        // <IconButton>
                        //     <DeleteIcon/>
                        // </IconButton>
                        // </Grid>
                        // </Grid> */}
                        <CardContent sx={{ marginTop: -1 }}>
                            { store.customerQRInfo.qrList[isSelected].qrInfo.map((qrInfo, index) => (
                                <QRInfoCard store={store} key={ qrInfo.qrID } qrInfo={ qrInfo } postQRInfoGlobal={store.postQRInfoGlobal} handleDeleteConfirm={ () => { handleDeleteClick(index) } }/>
                            )) }
                            {
                                addClicked &&
                                <Card sx={{ boxShadow: 4 }}>
                                    <Box width={"100%"} padding={3}>
                                        <TextField
                                        fullWidth
                                        placeholder="https://theqrstore.in"
                                        label="QR Title"
                                        variant="outlined"
                                        color="secondary"
                                        margin="normal"
                                        value={qrName}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setQRName(e.target.value); }}
                                        />
                                        <TextField
                                        fullWidth
                                        placeholder="https://theqrstore.in"
                                        label="Your Website URL"
                                        variant="outlined"
                                        color="secondary"
                                        margin="normal"
                                        value={webpageLink}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setWebpageLink(e.target.value); }}
                                        />
                                        <TextField
                                        fullWidth
                                        label="Description (Optional)"
                                        variant="outlined"
                                        color="secondary"
                                        margin="normal"
                                        value={ qrDescription ?? ""}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setQRDescription(e.target.value); }}
                                        />
                                        <Button
                                        variant="outlined"
                                        color='secondary'
                                        sx={{ marginTop: 2, marginRight: 2 }}
                                        onClick={() => { handleNextClick(); }}
                                        >
                                        Next
                                        </Button>
                                        <ThemeProvider theme = {redTheme}>
                                            <Button
                                            variant="outlined"
                                            onClick={() => { handleCancelClick(); }}
                                            sx={{ marginTop: 2, marginLeft: 2 }}
                                            >
                                            Cancel
                                            </Button>
                                        </ThemeProvider>
                                    </Box>
                                </Card>
                            }
                            {
                                !addClicked &&
                                <Card sx={{ boxShadow: 4 }}>
                                    <ButtonBase sx={{ width: "100%" }} onClick={ handleAddQRClick } disabled={ addClicked }>
                                        <Box width={"100%"}>
                                            <Typography variant={isTab ? "body1" : "h6"} marginTop={1} marginBottom={1}>
                                                Create another QR with the same image
                                            </Typography>
                                            <CardMedia
                                                component="img"
                                                image = {IMAGE_URI_BASE + "DarkPlusSquare.jpg"}
                                                crossOrigin='anonymous'
                                                alt="Paella dish"
                                                sx={{ width: "30%", maxWidth: 100, margin: "auto", marginBottom: 1 }}
                                            />
                                        </Box>
                                    </ButtonBase>
                                </Card>
                            }
                        </CardContent>
                        <CardActions disableSpacing>
                        </CardActions>
                    </Box>
                    </Card>
                }
                <Box sx={{ position: "sticky", bottom: "3%", left: 5, display: "flex" }}>
                    <Box sx={{ backgroundColor: "rgba(170, 255, 255, 0.9)", borderRadius: 7, paddingLeft: 2, paddingRight: 2, paddingTop: 1, paddingBottom: 1, zIndex: 10, textAlign: "left" }}>
                        <Typography variant= { isMobile ? "body2" : "body1" }>
                            {`ImageQRs - ${String(store.customerQRInfo.noOfActiveQR)}/${String(store.customerQRInfo.maxNoOfActiveQR)}`}
                        </Typography>
                        <Link href={`${store.stageConfig.webUrl}/contactUs?page=2`} color="#161616" variant= { isMobile ? "body2" : "body1" }>
                            Contact Us To Get More
                        </Link>
                    </Box>
                </Box>
            </Box>
        }
        {
            store.customerQRInfo.qrList.length === 0 &&
            <Box height={500}>
            </Box>
        }
        <LimitReachedPopup store={store}></LimitReachedPopup>
        </Box>
    )
})