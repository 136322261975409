import { type QRInfo } from "src/types/QRInfoTypes";
import { qrStoreApi, qrStoreCustomerApi } from "./apiClients";
export async function getQRFromImage (qrInfo: QRInfo) {
  return (
    qrStoreApi.post(`/imagemodify`, {
      qrID: qrInfo.qrID,
      imageURL: qrInfo.imageURL,
      qrEndpoint: qrInfo.qrEndpoint,
      imageTemplate: qrInfo.imageTemplate
    })
  );
}

export async function saveImageQRAPI (qrInfo: QRInfo) {
  return (
    qrStoreApi.post(`/imageqr`, {
      qrID: qrInfo.qrID,
      imageURL: qrInfo.imageURL,
      qrEndpoint: qrInfo.qrEndpoint,
      imageTemplate: qrInfo.imageTemplate
    })
  );
}

export async function initializeQRInfoAPI (imageFileName: string, base64EncodedImage: string) {
  return (
    qrStoreApi.post(`/initQRInfo`, {
      imageFileName,
      base64EncodedImage
    })
  );
}

export async function getQRInfoAPI (qrID: string) {
  return (
    qrStoreApi.get(`/qrInfo?qrID=${qrID}`)
  );
}

export async function postQRInfoAPI (qrInfo: QRInfo) {
  return (
    qrStoreApi.post(`/qrInfo`, {
      qrID: qrInfo.qrID,
      qrName: qrInfo.qrName,
      imageURL: qrInfo.imageURL,
      qrImageURL: qrInfo.qrImageURL,
      qrEndpoint: qrInfo.qrEndpoint,
      imageTemplate: qrInfo.imageTemplate,
      templateID: qrInfo.templateID,
      templateName: qrInfo.templateName,
      websiteEndpoint: qrInfo.websiteEndpoint,
      qrDescription: qrInfo.qrDescription,
      createdDate: qrInfo.createdDate
    })
  );
}

export async function postQRInfoForCustomer (qrInfo: QRInfo) {
  return (
    qrStoreCustomerApi.post(`/qrInfoCustomer`, {
      qrID: qrInfo.qrID,
      qrName: qrInfo.qrName,
      imageURL: qrInfo.imageURL,
      qrImageURL: qrInfo.qrImageURL,
      qrEndpoint: qrInfo.qrEndpoint,
      templateID: qrInfo.templateID,
      templateName: qrInfo.templateName,
      imageTemplate: qrInfo.imageTemplate,
      websiteEndpoint: qrInfo.websiteEndpoint,
      qrDescription: qrInfo.qrDescription,
      createdDate: qrInfo.createdDate
    })
  );
}

export async function postQRFromTemplate (qrInfo: QRInfo) {
  return (
    qrStoreCustomerApi.post(`/qrFromTemplate`, {
      qrID: qrInfo.qrID,
      qrName: qrInfo.qrName,
      imageURL: qrInfo.imageURL,
      qrImageURL: qrInfo.qrImageURL,
      qrEndpoint: qrInfo.qrEndpoint,
      templateID: qrInfo.templateID,
      templateName: qrInfo.templateName,
      imageTemplate: qrInfo.imageTemplate,
      websiteEndpoint: qrInfo.websiteEndpoint,
      qrDescription: qrInfo.qrDescription,
      createdDate: qrInfo.createdDate
    })
  );
}

export async function getQRInfoForCustomer () {
  return (
    qrStoreCustomerApi.get(`/qrInfoCustomer`)
  );
}

export async function deleteQRForCustomer (qrID: string) {
  return (
    qrStoreCustomerApi.delete(`/qrInfo?qrID=${qrID}`)
  );
}

export async function subscribeToNewsletter (email: string) {
  return (
    qrStoreApi.post(`/subscribenewsletter`, {
      email
    })
  );
}
