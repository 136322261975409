import axios, { type AxiosInstance, type AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import { getCognitoAccessToken } from 'src/utils/tokenUtils';
import { type RequestInterface } from './apiClientBase';
import { getCurrentStageConfig } from './stageConfig';

export default function (requestBase: RequestInterface): AxiosInstance {
  const customAxios = axios.create({
    baseURL: requestBase.baseURL,
    responseType: 'json' as const,
    headers: {
      // 'Content-Type': 'application/json' //any other common headers go here
    },
    timeout: 10 * 1000 // set to 10sec we can change or remove as the request will be aborted after 10 sec
  })
  axiosRetry(customAxios, {
    retries: 1,
    retryDelay: axiosRetry.exponentialDelay
  })

  // const userAuthProvider = new UserAuthProvider();
  // const cognitoHeader = 'X-Amz-Cognito';
  //
  const isCustomerRequest = () =>
      requestBase.userType && requestBase.userType === 'customer';
  //
  // // request handler that gets fired from interceptor
  const requestHandler = async (request: any) : Promise<any> => {
    console.log("In Handler");
    if (isCustomerRequest()) {
        const cognitoAccessToken = getCognitoAccessToken();
        console.log("In Customer");
        if (cognitoAccessToken != null) { request.headers.Authorization = cognitoAccessToken; }
    }
    return request
  }

  // response handler that gets fired from interceptor
  const responseHandler = async (response: any) : Promise<any> => {
    switch (response.status) {
      case 401:
        response.errorCode = 'UN_AUTHORIZED'
        // if (isCustomerRequest(response.config)) {
        //     window.location.replace('/login');
        // }
        break
      case 403:
        response.errorCode = 'FORBIDDEN'
        break
      case 404:
        response.errorCode = 'NOT_FOUND'
        break
      case 500:
        response.errorCode = 'INTERNAL_SERVER_ERROR'
        break
      case 200:
        response.successCode = 'SUCCESS'
        break
      default:
        response.errorCode = ''
    }

    return response
  }

  const errorHandler = async (error: any) : Promise<any> => {
    const status = error.response.status
    if (
        status === 401 &&
        isCustomerRequest()
    ) {
      const val = window.location.href;
      window.location.href = getCurrentStageConfig().loginURLBase + window.location.href;
      console.log(val);
    }
    // else if (status ===400) {
    //   if (error.response.data.match("\W*(Active QRs exceeded limit)\W*")) {
    //     store.
    //   }
    // }
    // console.log("Axios Error ", JSON.stringify(error.response))
    await Promise.reject(error)
  }

  customAxios.interceptors.request.use(async (request: AxiosRequestConfig) =>
    await requestHandler(request)
  )

  customAxios.interceptors.response.use(
    async (response: any) => await responseHandler(response),
    async (error: any) => { await errorHandler(error) }
  )

  return customAxios
}
