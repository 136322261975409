export interface StageConfig {
  env: string,
  region: string,
  baseUrl: string,
  webUrl: string,
  imageURLBase: string,
  qrImageURLBase: string,
  loginURLBase: string,
  logoutURLBase: string,
}
type StageConfigs = Record<string, StageConfig>;
const STAGE_MAPPING: any = {
  'beta.theqrstore.in': 'beta',
  'www.theqrstore.in': 'prod',
  'theqrstore.in': 'prod'
}
const DEFAULT_STAGE = 'local';

const TheQRStoreStageConfigs: StageConfigs = {
  local: {
    env: 'local',
    region: 'ap-south-1',
    baseUrl: 'http://localhost',
    webUrl: 'http://localhost:3000',
    imageURLBase: "https://beinfrastack-betaimagebucket3e2e28b2-1ti4811auesmn.s3.ap-south-1.amazonaws.com/",
    qrImageURLBase: "https://beinfrastack-betaqrimagebucket04ee1251-1gkqejjgu2kd6.s3.ap-south-1.amazonaws.com/",
    loginURLBase: "https://beta-auth.theqrstore.in/login?client_id=4atd0oevuhok1jk2i8ce9asv4g&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=",
    logoutURLBase: 'https://beta-auth.theqrstore.in/logout?client_id=4atd0oevuhok1jk2i8ce9asv4g&logout_uri='
  },
  beta: {
    env: 'beta',
    region: 'ap-south-1',
    baseUrl: 'https://api-beta.theqrstore.in',
    webUrl: 'https://beta.theqrstore.in',
    imageURLBase: "https://beinfrastack-betaimagebucket3e2e28b2-1ti4811auesmn.s3.ap-south-1.amazonaws.com/",
    qrImageURLBase: "https://beinfrastack-betaqrimagebucket04ee1251-1gkqejjgu2kd6.s3.ap-south-1.amazonaws.com/",
    loginURLBase: "https://beta-auth.theqrstore.in/login?client_id=4atd0oevuhok1jk2i8ce9asv4g&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=",
    logoutURLBase: 'https://beta-auth.theqrstore.in/logout?client_id=4atd0oevuhok1jk2i8ce9asv4g&logout_uri='
  },
  prod: {
    env: 'prod',
    region: 'ap-south-1',
    baseUrl: 'https://api.theqrstore.in',
    webUrl: 'https://theqrstore.in',
    imageURLBase: "https://beinfrastack-prodimagebucketaeb36453-5ygbmttkcvsz.s3.ap-south-1.amazonaws.com/",
    qrImageURLBase: "https://beinfrastack-prodqrimagebucketf7ffaaee-18w5xwjpi4gl1.s3.ap-south-1.amazonaws.com/",
    loginURLBase: "https://auth.theqrstore.in/login?client_id=585dnd9vvva3tr62jhjfgakija&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=",
    logoutURLBase: 'https://auth.theqrstore.in/logout?client_id=585dnd9vvva3tr62jhjfgakija&logout_uri='
  }
}

export function getCurrentStageConfig (): StageConfig {
  const currentHostName = window.location.hostname;
  console.log("hostName ", currentHostName);
  const stageName = STAGE_MAPPING[currentHostName] || DEFAULT_STAGE;
  return TheQRStoreStageConfigs[stageName];
}