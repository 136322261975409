export const URL_SHORTENER_BASE = "https://tqrs.in/"
export const WEB_URL_BASE = "https://theqrstore.in/"
export const IMAGE_URI_BASE = "https://d1y6ghgsb85pog.cloudfront.net/"
// export const QRImageList = [
//     {
//       thumbnail: {
//         uri: `${IMAGE_URI_BASE}messiQR2.jpeg`,
//         placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg`
//       }
//     },
//     { thumbnail: { uri: `${IMAGE_URI_BASE}messiQR2.jpeg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
//     { thumbnail: { uri: `${IMAGE_URI_BASE}messiQR2.jpeg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
//     { thumbnail: { uri: `${IMAGE_URI_BASE}messiQR2.jpeg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
//     {
//       thumbnail: { uri: `${IMAGE_URI_BASE}messiQR2.jpeg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` }
//     },
//     { thumbnail: { uri: `${IMAGE_URI_BASE}messiQR2.jpeg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
//     { thumbnail: { uri: `${IMAGE_URI_BASE}messiQR2.jpeg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
//     { thumbnail: { uri: `${IMAGE_URI_BASE}messiQR2.jpeg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
//     { thumbnail: { uri: `${IMAGE_URI_BASE}messiQR2.jpeg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
//     { thumbnail: { uri: `${IMAGE_URI_BASE}messiQR2.jpeg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
//     { thumbnail: { uri: `${IMAGE_URI_BASE}messiQR2.jpeg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
//     { thumbnail: { uri: `${IMAGE_URI_BASE}messiQR2.jpeg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
//     {
//       thumbnail: { uri: `${IMAGE_URI_BASE}messiQR2.jpeg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` }
//     },
//     { thumbnail: { uri: `${IMAGE_URI_BASE}messiQR2.jpeg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
//     { thumbnail: { uri: `${IMAGE_URI_BASE}messiQR2.jpeg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
//     { thumbnail: { uri: `${IMAGE_URI_BASE}messiQR2.jpeg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } }
//   ];
export const QRImageList = [
  {
    thumbnail: {
      uri: `${IMAGE_URI_BASE}jordanShoe.jpg`,
      placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg`
    }
  },
  { thumbnail: { uri: `${IMAGE_URI_BASE}youtubeLogo3.jpg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
  { thumbnail: { uri: `${IMAGE_URI_BASE}bitmoji.png`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
  { thumbnail: { uri: `${IMAGE_URI_BASE}weddingScene.jpg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
  { thumbnail: { uri: `${IMAGE_URI_BASE}instaLogo4.jpg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
  { thumbnail: { uri: `${IMAGE_URI_BASE}Menu.jpg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
  { thumbnail: { uri: `${IMAGE_URI_BASE}bird.png`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
  {
    thumbnail: { uri: `${IMAGE_URI_BASE}im21.jpg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` }
  },
  { thumbnail: { uri: `${IMAGE_URI_BASE}spiderMan.jpg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
  { thumbnail: { uri: `${IMAGE_URI_BASE}diCaprioMeme.jpg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
  { thumbnail: { uri: `${IMAGE_URI_BASE}messiWorldCup.jpg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
  { thumbnail: { uri: `${IMAGE_URI_BASE}motorcycle.jpg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
  { thumbnail: { uri: `${IMAGE_URI_BASE}Christmas.jpg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
  { thumbnail: { uri: `${IMAGE_URI_BASE}coffee.jpg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
  { thumbnail: { uri: `${IMAGE_URI_BASE}Maxwell.jpg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
  { thumbnail: { uri: `${IMAGE_URI_BASE}Wifi2.jpg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
  { thumbnail: { uri: `${IMAGE_URI_BASE}bitmojiFace.jpg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } },
  { thumbnail: { uri: `${IMAGE_URI_BASE}RockyScenary.jpg`, placeholder: `${IMAGE_URI_BASE}messiQR2.jpeg` } }
];