import { Box, ButtonBase, Card, CardMedia, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React, { useContext, type FC } from "react";
import { isMobileContext, isTabContext } from "src/contexts/rootContext";
import { type RootStore } from "src/store/RootStore";
import { type QRInfoListPerTemplate } from "src/types/QRInfoTypes";

interface ITemplateCardProps {
    store: RootStore
    templateInfo: QRInfoListPerTemplate;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const TemplateCard:FC<ITemplateCardProps> = observer(({ store, templateInfo, onClick }:ITemplateCardProps) => {
    const isMobile = useContext(isMobileContext);
    const isTab = useContext(isTabContext);
    return (
        <Card sx={{ width: isTab ? isMobile ? 130 : 200 : 230, minWidth: isTab ? isMobile ? 130 : 200 : 230, boxShadow: 3, marginBottom: 2, marginRight: isMobile ? 1 : 5 }}>
            <ButtonBase onClick={onClick}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant={isMobile ? "body2" : "body1"} marginTop={1} marginLeft={1}>
                        {templateInfo.templateName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {`No. of QRs : ${String(templateInfo.qrInfo.length)}`}
                    </Typography>
                    <CardMedia
                        component="img"
                        image={ store.stageConfig.qrImageURLBase + String(templateInfo.qrInfo[0].qrImageURL ?? "") }
                        crossOrigin="anonymous"
                        alt="Paella dish"
                        sx={{ width: "100%", aspectRatio: 1 }}
                    />
                </Box>
            </ButtonBase>
        </Card>
    )
})
