import { Box } from "@mui/material";
import { observer } from "mobx-react";
import React, { useEffect, useState, type FC } from 'react';
import { type RootStore } from "./store/RootStore";
// import Footer from "./components/Footer";
import Footer from "./components/Footer";
import FooterForMobile from "./components/FooterForMobile";
import { isMobileContext, isTabContext } from './contexts/rootContext';
// import { Image2QRPage } from './pages/Image2QR';
import {
  Route,
  Routes
} from "react-router-dom";
import ErrorPage from './pages/ErrorPage';
import { LandingPage } from './pages/LandingPage';
import { setTokensInLocalStorage } from './utils/tokenUtils';
// import { ImageUploadPage } from './pages/ImageUpload';
import { CognitoIdentityProviderClient, GetUserCommand } from "@aws-sdk/client-cognito-identity-provider";
import { LimitReachedPopup } from "./components/LimitReachedPopup";
import { Masthead } from "./components/MastHead";
import { ContactUsPage } from "./pages/ContactUs";
import { QRGenWorkflowPage } from './pages/QRGenWorkflow';
import { QRManagementPage } from './pages/QRManagement';

interface AppProps {
  store: RootStore;
}

export const App:FC<AppProps> = observer(({ store }:AppProps) => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 600);
  const [isTab, setIsTab] = useState<boolean>(window.innerWidth <= 900);

  function handleWindowSizeChange () {
    setIsMobile(window.innerWidth <= 600);
    setIsTab(window.innerWidth <= 900);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    console.log(store.isLoggedIn)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    if (!store.isLoggedIn) {
      setTokensInLocalStorage();
      const accessToken = localStorage.getItem("qrstore_access_token");
      if (accessToken) {
        const cognitoIdentityProviderClient = new CognitoIdentityProviderClient({
          region: "ap-south-1"
        });
        const input = { // GetUserRequest
          AccessToken: accessToken // required
        };
        const command = new GetUserCommand(input);
        console.log("I'm here");
        (
          async () => {
            const response = await cognitoIdentityProviderClient.send(command);
            const userAttributes = response.UserAttributes
            userAttributes?.forEach( (attribute) => {
              if (attribute.Name === "email") {
                store.emailID = attribute.Value ?? ""
              }
            })
            store.isLoggedIn = true
            console.log(response)
          }
        )().catch((error) => {
          console.log("Error while getting userName", error)
          // window.location.href = 'https://beta-auth.theqrstore.in/login?client_id=4atd0oevuhok1jk2i8ce9asv4g&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=' + window.location.href;
        })
      }
    }
  }, []);

  return (
    <isMobileContext.Provider value= { isMobile } >
      <isTabContext.Provider value= { isTab } >
      <Box textAlign={"center"}>
        <Masthead store={ store }/>
        <Routes>
          <Route path="/" element={<LandingPage store={ store }/>}/>
          <Route path="/qrgenworkflow/*" element={<QRGenWorkflowPage store={ store }/>}/>
          {/* <Route path="/imageupload" element={<ImageUploadPage store={ store }/>}/>
          <Route path="/image2qr" element={<Image2QRPage store={ store }/>}/>
          <Route path="/connectPage" element={<Image2QRPage store={ store }/>}/>
          <Route path="/login" element={<Image2QRPage store={ store }/>}/> */}
          <Route path="/managementPortal" element={<QRManagementPage store={ store }/>}/>
          <Route path="/contactUs" element={<ContactUsPage store={ store }/>}/>
          {/* <Route path="/TandC" element={<TandCPage />}/>
          <Route path="/privacyPolicy" element={<PrivacyPolicyPage />}/> */}
          <Route path="*" element={<ErrorPage/>}/>
        </Routes>
        <LimitReachedPopup store={ store }/>
        { isMobile &&
          <FooterForMobile/>
        }
        { !isMobile &&
          <Footer/>
        }
      </Box>
      </isTabContext.Provider>
    </isMobileContext.Provider>
  )
});
