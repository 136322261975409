/**
 * @param {HTMLImageElement} image - Image File Object
 * @param {Object} crop - crop Object
 */
export async function getCroppedImg (image:any, crop:any):Promise<Blob> {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");
  if (ctx) {
    // New lines to be added
    // const pixelRatio = window.devicePixelRatio;
    const widthFactor = (crop.unit === 'px' ? 1 : image.width / 100);
    const heightFactor = (crop.unit === 'px' ? 1 : image.height / 100);
    canvas.width = widthFactor * crop.width * scaleX;
    canvas.height = canvas.width;
    // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      widthFactor * crop.x * scaleX,
      heightFactor * crop.y * scaleY,
      widthFactor * crop.width * scaleX,
      heightFactor * crop.height * scaleY,
      0,
      0,
      widthFactor * crop.width * scaleX,
      heightFactor * crop.height * scaleY
    );
  }

  return await new Promise<Blob>((resolve) => {
    canvas.toBlob(
      (blob) => {
        // console.log("Blob", blob);
        resolve(blob ?? new Blob());
      },
      "image/jpeg",
      1
    );
  });
}

export const convertToBase64 = async (file:Blob) => await new Promise<string>((resolve, reject) => {
  const fileReader = new FileReader();
  // console.log(file);
  fileReader.readAsDataURL(file );
  fileReader.onloadend = () => {
    resolve(fileReader.result as string);
  };
  fileReader.onerror = (error) => {
    reject(error);
  };
});
