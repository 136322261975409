import React, { PureComponent, createRef, type PointerEvent as ReactPointerEvent } from 'react'
import clsx from 'clsx'

import { type Ords, type XYOrds, type Crop, type PixelCrop, type PercentCrop, type IArea, type IPixelArea, type IPropUnit } from 'src/types/CropSelectorTypes'
import {
  defaultCrop,
  clamp,
  areCropsEqual,
  convertToPercentCrop,
  convertToPixelCrop,
  convertToPixelArea,
  containCrop,
  nudgeCrop, isSubElement, formatArea, areAreasEqual, containArea
} from 'src/utils/cropSelectorUtils'

import './CustomCropperAndSelector.scss'
import { Area } from "./Area";

interface EVData {
  startClientX: number
  startClientY: number
  startCropX: number
  startCropY: number
  clientX: number
  clientY: number
  isResize: boolean
  ord?: Ords
}

interface Rectangle {
  x: number
  y: number
  width: number
  height: number
}

const DOC_MOVE_OPTS = { capture: true, passive: false }

export interface ReactCropProps {
  /** An object of labels to override the built-in English ones */
  ariaLabels?: {
    cropArea: string
    nwDragHandle: string
    nDragHandle: string
    neDragHandle: string
    eDragHandle: string
    seDragHandle: string
    sDragHandle: string
    swDragHandle: string
    wDragHandle: string
  }
  /** The aspect ratio of the crop, e.g. `1` for a square or `16 / 9` for landscape. */
  aspect?: number
  /** Classes to pass to the `ReactCrop` element. */
  className?: string
  /** The elements that you want to perform a crop on. For example
   * an image or video. */
  children?: React.ReactNode
  /** Show the crop area as a circle. If your aspect is not 1 (a square) then the circle will be warped into an oval shape. Defaults to false. */
  circularCrop?: boolean
  /** Since v10 all crop params are required except for aspect. Omit the entire crop object if you don't want a crop. See README on how to create an aspect crop with a % crop. */
  crop: Crop

  areas: IArea[]

  maxAreas: number

  areaUnit?: IPropUnit
  /** If true then the user cannot resize or draw a new crop. A class of `ReactCrop--disabled` is also added to the container for user styling. */
  disabled?: boolean
  /** If true then the user cannot create or resize a crop, but can still drag the existing crop around. A class of `ReactCrop--locked` is also added to the container for user styling. */
  locked?: boolean
  /** If true is passed then selection can't be disabled if the user clicks outside the selection area. */
  keepSelection?: boolean
  /** A minimum crop width, in pixels. */
  minWidth?: number
  /** A minimum crop height, in pixels. */
  minHeight?: number
  /** A maximum crop width, in pixels. */
  maxWidth?: number
  /** A maximum crop height, in pixels. */
  maxHeight?: number
  /** A callback which happens for every change of the crop. You should set the crop to state and pass it back into the library via the `crop` prop. */
  onChange: (crop: PixelCrop, areas: IArea[], percentageCrop: PercentCrop) => void
  /** A callback which happens after a resize, drag, or nudge. Passes the current crop state object in pixels and percent. */
  onComplete?: (crop: PixelCrop, percentageCrop: PercentCrop) => void
  /** A callback which happens when a user starts dragging or resizing. It is convenient to manipulate elements outside this component. */
  onDragStart?: (e: PointerEvent) => void
  /** A callback which happens when a user releases the cursor or touch after dragging or resizing. */
  onDragEnd?: (e: PointerEvent) => void
  /** Render a custom element in crop selection. */
  renderSelectionAddon?: (state: ReactCropState) => React.ReactNode
  /** Show rule of thirds lines in the cropped area. Defaults to false. */
  ruleOfThirds?: boolean
  /** Inline styles object to be passed to the `ReactCrop` element. */
  style?: React.CSSProperties
}

export interface ReactCropState {
  cropIsActive: boolean
  newCropIsBeingDrawn: boolean
}

export class CustomCropperAndSelector extends PureComponent<ReactCropProps, ReactCropState> {
  static xOrds = ['e', 'w']
  static yOrds = ['n', 's']
  static xyOrds = ['nw', 'ne', 'se', 'sw']

  static nudgeStep = 1
  static nudgeStepMedium = 10
  static nudgeStepLarge = 100

  static defaultProps = {
    ariaLabels: {
      cropArea: 'Use the arrow keys to move the crop selection area',
      nwDragHandle: 'Use the arrow keys to move the north west drag handle to change the crop selection area',
      nDragHandle: 'Use the up and down arrow keys to move the north drag handle to change the crop selection area',
      neDragHandle: 'Use the arrow keys to move the north east drag handle to change the crop selection area',
      eDragHandle: 'Use the up and down arrow keys to move the east drag handle to change the crop selection area',
      seDragHandle: 'Use the arrow keys to move the south east drag handle to change the crop selection area',
      sDragHandle: 'Use the up and down arrow keys to move the south drag handle to change the crop selection area',
      swDragHandle: 'Use the arrow keys to move the south west drag handle to change the crop selection area',
      wDragHandle: 'Use the up and down arrow keys to move the west drag handle to change the crop selection area'
    }
  }

  get document () {
    return document
  }

  docMoveBound = false
  mouseDownOnCrop = false
  dragStarted = false
  evData: EVData = {
    startClientX: 0,
    startClientY: 0,
    startCropX: 0,
    startCropY: 0,
    clientX: 0,
    clientY: 0,
    isResize: true
  }

  areaCounter = 0;
  areaStatus = {
    areaChangeIndex: 0,
    isChanging: false
  }

  componentRef = createRef<HTMLDivElement>()
  mediaRef = createRef<HTMLDivElement>()
  resizeObserver?: ResizeObserver
  initChangeCalled = false;

  state: ReactCropState = {
    cropIsActive: false,
    newCropIsBeingDrawn: false
  };


  makePixelArea (area: IArea) {
    const box = this.getBox();
    return convertToPixelArea(area, box.width, box.height);
  };

  // We unfortunately get the bounding box every time as x+y changes
  // due to scrolling.
  getBox (): Rectangle {
    const el = this.mediaRef.current
    // console.log("box",el);
    if (!el) {
      return { x: 0, y: 0, width: 0, height: 0 }
    }
    const { x, y, width, height } = el.getBoundingClientRect()
    // console.log("boxVals",x, y, width, height);
    return { x, y, width, height }
  }

  componentDidUpdate (prevProps: ReactCropProps) {
    const { crop, onComplete } = this.props

    // Useful for when programatically setting a new
    // crop and wanting to show a preview.
    if (onComplete && !prevProps.crop && crop) {
      const { width, height } = this.getBox()
      if (width && height) {
        onComplete(convertToPixelCrop(crop, width, height), convertToPercentCrop(crop, width, height))
      }
    }
  }

  componentWillUnmount () {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  }

  bindDocMove () {
    if (this.docMoveBound) {
      return
    }

    this.document.addEventListener('pointermove', this.onDocPointerMove, DOC_MOVE_OPTS)
    this.document.addEventListener('pointerup', this.onDocPointerDone, DOC_MOVE_OPTS)
    this.document.addEventListener('pointercancel', this.onDocPointerDone, DOC_MOVE_OPTS)

    this.docMoveBound = true
  }

  unbindDocMove () {
    if (!this.docMoveBound) {
      return
    }

    this.document.removeEventListener('pointermove', this.onDocPointerMove, DOC_MOVE_OPTS)
    this.document.removeEventListener('pointerup', this.onDocPointerDone, DOC_MOVE_OPTS)
    this.document.removeEventListener('pointercancel', this.onDocPointerDone, DOC_MOVE_OPTS)

    this.docMoveBound = false
  }

  onCropPointerDown = (e: React.PointerEvent<HTMLDivElement>) => {
    const { crop, areas, onChange, maxAreas, areaUnit, disabled } = this.props
    const box = this.getBox()
    console.log("onCropPointerDown", e.clientX, e.clientY, (e.target as HTMLElement).dataset.ord, e);

    if (!crop) {
      return
    }

    const pixelCrop = convertToPixelCrop(crop, box.width, box.height)

    if (e.cancelable) e.preventDefault() // Stop drag selection.

    // Bind to doc to follow movements outside of element.
    this.bindDocMove()

    // Focus for detecting keypress.
    ;(this.componentRef.current as HTMLDivElement).focus({ preventScroll: true })

    if (disabled) {
      const target = e.currentTarget;
      if (
        target.dataset.wrapper ??
        target.dataset.direction ??
        isSubElement(target, (element) => element.dataset?.wrapper)
      ) {
        return;
      }

      const areaX = e.clientX - box.x;
      const areaY = e.clientY - box.y;
      const nextArea: IPixelArea = {
        unit: 'px',
        x: areaX,
        y: areaY,
        width: 0,
        height: 0,
        isChanging: true,
        isNew: false
      };

      this.evData = {
        startClientX: e.clientX,
        startClientY: e.clientY,
        startCropX: areaX,
        startCropY: areaY,
        clientX: e.clientX,
        clientY: e.clientY,
        isResize: true
      };

      this.areaCounter = this.areaCounter + 1;

      const area = formatArea(nextArea, box.width, box.height, areaUnit ?? "pixel");
      let areaIndex: number;
      if (areas.length < maxAreas) {
        onChange(convertToPixelCrop(crop, box.width, box.height), areas.concat(area), convertToPercentCrop(crop, box.width, box.height));
        areaIndex = areas.length;
      } else {
        onChange(convertToPixelCrop(crop, box.width, box.height), [...areas.slice(0, maxAreas - 1), area], convertToPercentCrop(crop, box.width, box.height));
        areaIndex = maxAreas - 1;
      }
      this.areaStatus = {
        areaChangeIndex: areaIndex,
        isChanging: true
      };
      this.mouseDownOnCrop = true
      return;
    }

    const ord = (e.target as HTMLElement).dataset.ord as Ords
    const isResize = Boolean(ord)
    let startClientX = e.clientX
    let startClientY = e.clientY
    let startCropX = pixelCrop.x
    let startCropY = pixelCrop.y

    // Set the starting coords to the opposite corner.
    if (ord) {
      const relativeX = e.clientX - box.x
      const relativeY = e.clientY - box.y
      let fromCornerX = 0
      let fromCornerY = 0

      if (ord === 'ne' || ord === 'e') {
        fromCornerX = relativeX - (pixelCrop.x + pixelCrop.width)
        fromCornerY = relativeY - pixelCrop.y
        startCropX = pixelCrop.x
        startCropY = pixelCrop.y + pixelCrop.height
      } else if (ord === 'se' || ord === 's') {
        fromCornerX = relativeX - (pixelCrop.x + pixelCrop.width)
        fromCornerY = relativeY - (pixelCrop.y + pixelCrop.height)
        startCropX = pixelCrop.x
        startCropY = pixelCrop.y
      } else if (ord === 'sw' || ord === 'w') {
        fromCornerX = relativeX - pixelCrop.x
        fromCornerY = relativeY - (pixelCrop.y + pixelCrop.height)
        startCropX = pixelCrop.x + pixelCrop.width
        startCropY = pixelCrop.y
      } else if (ord === 'nw' || ord === 'n') {
        fromCornerX = relativeX - pixelCrop.x
        fromCornerY = relativeY - pixelCrop.y
        startCropX = pixelCrop.x + pixelCrop.width
        startCropY = pixelCrop.y + pixelCrop.height
      }

      startClientX = startCropX + box.x + fromCornerX
      startClientY = startCropY + box.y + fromCornerY
    }

    this.evData = {
      startClientX,
      startClientY,
      startCropX,
      startCropY,
      clientX: e.clientX,
      clientY: e.clientY,
      isResize,
      ord
    }

    this.mouseDownOnCrop = true
    this.setState({ cropIsActive: true })
  }

  onAreaPointerDown = (
  e: ReactPointerEvent<HTMLDivElement>,
  index: number
  ) => {
    const { areas, disabled } = this.props;
    this.bindDocMove();
    console.log("onAreaPointerDown", e.clientX, e.clientY, (e.target as HTMLElement).dataset.ord, e);
    if (e.cancelable) e.preventDefault()

    if (disabled) {
      const box = this.getBox();
      const area = areas[index];
      const pixelArea = convertToPixelArea(area, box.width, box.height);
      console.log(e);
      const ord = (e.target as HTMLElement).dataset.ord as Ords;
      const isResize = Boolean(ord);
      let startClientX = e.clientX;
      let startClientY = e.clientY;
      let startAreaX = pixelArea.x;
      let startAreaY = pixelArea.y;

      // Set the starting coords to the opposite corner.
      if (ord) {
        if (ord === 'ne' || ord === 'e') {
          startAreaX = pixelArea.x;
          startAreaY = pixelArea.y + pixelArea.height;
        } else if (ord === 'se' || ord === 's') {
          startAreaX = pixelArea.x;
          startAreaY = pixelArea.y;
        } else if (ord === 'sw' || ord === 'w') {
          startAreaX = pixelArea.x + pixelArea.width;
          startAreaY = pixelArea.y;
        } else if (ord === 'nw' || ord === 'n') {
          startAreaX = pixelArea.x + pixelArea.width;
          startAreaY = pixelArea.y + pixelArea.height;
        }

        startClientX = startAreaX + box.x;
        startClientY = startAreaY + box.y;
      }
      this.evData = {
        startClientX,
        startClientY,
        startCropX: startAreaX,
        startCropY: startAreaY,
        clientX: e.clientX,
        clientY: e.clientY,
        isResize,
        ord
      };
      this.areaStatus = {
        isChanging: true,
        areaChangeIndex: index
      }
    }
    this.mouseDownOnCrop = true
  }

  onComponentPointerDown = (e: React.PointerEvent<HTMLDivElement>) => {
    const { crop, areas, disabled, locked, keepSelection, onChange } = this.props
    const box = this.getBox()
    if (e.cancelable) e.preventDefault() // Stop drag selection.

    // Bind to doc to follow movements outside of element.
    this.bindDocMove()
    console.log("onComponentPointerDown", e.clientX, e.clientY, (e.target as HTMLElement).dataset.ord, e);

    if (disabled ?? locked ?? (keepSelection && crop)) {
      return
    }

    // Focus for detecting keypress.
    ;(this.componentRef.current as HTMLDivElement).focus({ preventScroll: true })

    const cropX = e.clientX - box.x
    const cropY = e.clientY - box.y
    const nextCrop: PixelCrop = {
      unit: 'px',
      x: cropX,
      y: cropY,
      width: 0,
      height: 0
    }

    this.evData = {
      startClientX: e.clientX,
      startClientY: e.clientY,
      startCropX: cropX,
      startCropY: cropY,
      clientX: e.clientX,
      clientY: e.clientY,
      isResize: true
    }

    this.mouseDownOnCrop = true

    onChange(convertToPixelCrop(nextCrop, box.width, box.height), areas, convertToPercentCrop(nextCrop, box.width, box.height))

    this.setState({ cropIsActive: true, newCropIsBeingDrawn: true })
  }

  dragArea (updatedArea: IArea) {
      const box = this.getBox();
      const nextArea = this.makePixelArea(updatedArea);
      console.log("dragArea", this.evData.clientX, this.evData.clientY, this.evData.ord, this.evData);
      const xDiff = this.evData.clientX - this.evData.startClientX;
      const yDiff = this.evData.clientY - this.evData.startClientY;

      nextArea.x = clamp(
        this.evData.startCropX + xDiff,
        0,
        box.width - nextArea.width
      );
      nextArea.y = clamp(
        this.evData.startCropY + yDiff,
        0,
        box.height - nextArea.height
      );
      return nextArea;
    }

  resizeArea (updatedArea: IArea) {
      const box = this.getBox();
      const direction = this.getPointRegion(box);
      const nextArea = this.makePixelArea(updatedArea);
      const resolvedOrd: Ords = this.evData.ord ? this.evData.ord : direction;
      console.log("resizeArea", this.evData.clientX, this.evData.clientY, this.evData.ord, this.evData);
      const xDiff = this.evData.clientX - this.evData.startClientX;
      const yDiff = this.evData.clientY - this.evData.startClientY;

      const tmpArea: IPixelArea = {
        unit: 'px',
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        isChanging: true,
        isNew: false
      };
      if (direction === 'ne') {
        tmpArea.x = this.evData.startCropX;
        tmpArea.width = xDiff;
        tmpArea.height = Math.abs(yDiff);
        tmpArea.y = this.evData.startCropY - tmpArea.height;
      } else if (direction === 'se') {
        tmpArea.x = this.evData.startCropX;
        tmpArea.y = this.evData.startCropY;
        tmpArea.width = xDiff;
        tmpArea.height = yDiff;
      } else if (direction === 'sw') {
        tmpArea.x = this.evData.startCropX + xDiff;
        tmpArea.y = this.evData.startCropY;
        tmpArea.width = Math.abs(xDiff);

        tmpArea.height = yDiff;
      } else if (direction === 'nw') {
        tmpArea.x = this.evData.startCropX + xDiff;
        tmpArea.width = Math.abs(xDiff);
        tmpArea.height = Math.abs(yDiff);
        tmpArea.y = this.evData.startCropY + yDiff;
      }

      const containedArea = containArea(
        tmpArea,
        direction,
        box.width,
        box.height
      );

      // Apply x/y/width/height changes depending on ordinate
      // (fixed aspect always applies both).
      if (CustomCropperAndSelector.xyOrds.includes(resolvedOrd)) {
        nextArea.x = containedArea.x;
        nextArea.y = containedArea.y;
        nextArea.width = containedArea.width;
        nextArea.height = containedArea.height;
      } else if (CustomCropperAndSelector.xOrds.includes(resolvedOrd)) {
        nextArea.x = containedArea.x;
        nextArea.width = containedArea.width;
      } else if (CustomCropperAndSelector.yOrds.includes(resolvedOrd)) {
        nextArea.y = containedArea.y;
        nextArea.height = containedArea.height;
      }
      return nextArea;
    }

  onDocPointerMove = (e: PointerEvent) => {
    const { crop, areas, areaUnit, disabled, onChange, onDragStart } = this.props
    const box = this.getBox()

    if (!crop || !this.mouseDownOnCrop) {
      return
    }
    console.log("onDocPointerMove", e.clientX, e.clientY, (e.target as HTMLElement).dataset.ord, e);

    // Stop drag selection.
    if (e.cancelable) e.preventDefault()

    if (disabled) {
      // resize or move area
      const { isChanging, areaChangeIndex } = this.areaStatus;
      console.log(isChanging);
      if (!isChanging) {
        return;
      }
      const box = this.getBox();
      const updatedArea = areas[areaChangeIndex];
      this.evData.clientX = e.clientX
      this.evData.clientY = e.clientY
      let nextArea: IArea;

      if (this.evData.isResize) {
        nextArea = this.resizeArea(updatedArea);
      } else {
        nextArea = this.dragArea(updatedArea);
      }
      console.log("in1");
      if (!areAreasEqual(updatedArea, nextArea)) {
        console.log("in2");
        const area = formatArea(nextArea, box.width, box.height, areaUnit ?? "pixel");
        onChange(convertToPixelCrop(crop, box.width, box.height), [
          ...areas.slice(0, areaChangeIndex),
          { ...area },
          ...areas.slice(areaChangeIndex + 1)
        ], convertToPercentCrop(crop, box.width, box.height));
      }
      return;
    }

    if (!this.dragStarted) {
      this.dragStarted = true
      if (onDragStart) {
        onDragStart(e)
      }
    }

    // Update pointer position.
    const { evData } = this
    evData.clientX = e.clientX
    evData.clientY = e.clientY

    let nextCrop

    if (evData.isResize) {
      nextCrop = this.resizeCrop()
    } else {
      nextCrop = this.dragCrop()
    }

    if (!areCropsEqual(crop, nextCrop)) {
      onChange(
        convertToPixelCrop(nextCrop, box.width, box.height),
        areas,
        convertToPercentCrop(nextCrop, box.width, box.height)
      )
    }
  }

  onComponentKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { crop, areas, disabled, onChange, onComplete } = this.props
    const box = this.getBox()

    if (disabled) {
      return
    }

    const keyCode = e.key
    let nudged = false

    if (!crop) {
      return
    }

    const nextCrop = this.makePixelCrop()
    const ctrlCmdPressed = navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey
    const nudgeStep = ctrlCmdPressed
      ? CustomCropperAndSelector.nudgeStepLarge
      : e.shiftKey
        ? CustomCropperAndSelector.nudgeStepMedium
        : CustomCropperAndSelector.nudgeStep

    if (keyCode === 'ArrowLeft') {
      nextCrop.x -= nudgeStep
      nudged = true
    } else if (keyCode === 'ArrowRight') {
      nextCrop.x += nudgeStep
      nudged = true
    } else if (keyCode === 'ArrowUp') {
      nextCrop.y -= nudgeStep
      nudged = true
    } else if (keyCode === 'ArrowDown') {
      nextCrop.y += nudgeStep
      nudged = true
    }

    if (nudged) {
      if (e.cancelable) e.preventDefault() // Stop drag selection.

      nextCrop.x = clamp(nextCrop.x, 0, box.width - nextCrop.width)
      nextCrop.y = clamp(nextCrop.y, 0, box.height - nextCrop.height)

      const pixelCrop = convertToPixelCrop(nextCrop, box.width, box.height)
      const percentCrop = convertToPercentCrop(nextCrop, box.width, box.height)

      onChange(pixelCrop, areas, percentCrop)
      if (onComplete) {
        onComplete(pixelCrop, percentCrop)
      }
    }
  }

  onHandlerKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, ord: Ords) => {
    const {
      aspect = 0,
      crop,
      areas,
      disabled,
      minWidth = 0,
      minHeight = 0,
      maxWidth,
      maxHeight,
      onChange,
      onComplete
    } = this.props
    const box = this.getBox()

    if (disabled ?? !crop) {
      return
    }

    // Keep the event from bubbling up to the container
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      e.preventDefault()
    } else {
      return
    }

    const ctrlCmdPressed = navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey
    const offset = ctrlCmdPressed
      ? CustomCropperAndSelector.nudgeStepLarge
      : e.shiftKey
        ? CustomCropperAndSelector.nudgeStepMedium
        : CustomCropperAndSelector.nudgeStep

    const pixelCrop = convertToPixelCrop(crop, box.width, box.height)
    const nudgedCrop = nudgeCrop(pixelCrop, e.key, offset, ord)
    const containedCrop = containCrop(
      nudgedCrop,
      aspect,
      ord,
      box.width,
      box.height,
      minWidth,
      minHeight,
      maxWidth,
      maxHeight
    )

    if (!areCropsEqual(crop, containedCrop)) {
      const percentCrop = convertToPercentCrop(containedCrop, box.width, box.height)
      onChange(containedCrop, areas, percentCrop)

      if (onComplete) {
        onComplete(containedCrop, percentCrop)
      }
    }
  }

  onDocPointerDone = (e: PointerEvent) => {
    const { crop, areas, disabled, onChange, onComplete, onDragEnd } = this.props
    const box = this.getBox()
    console.log("onDocPointerDone", e.clientX, e.clientY, (e.target as HTMLElement).dataset.ord, e);

    this.unbindDocMove()

    if (!crop) {
      return
    }
    if (disabled) {
      const { isChanging, areaChangeIndex } = this.areaStatus;
      if (isChanging) {
        this.areaStatus = {
          isChanging: false,
          areaChangeIndex: -1
        };
        this.evData = {
          startClientX: 0,
          startClientY: 0,
          startCropX: 0,
          startCropY: 0,
          clientX: 0,
          clientY: 0,
          isResize: false
        };
        const updatedArea = areas[areaChangeIndex];

        onChange(convertToPixelCrop(crop, box.width, box.height), [
          ...areas.slice(0, areaChangeIndex),
          { ...updatedArea, isNew: false, isChanging: false },
          ...areas.slice(areaChangeIndex + 1)
        ], convertToPercentCrop(crop, box.width, box.height));
      }
      this.mouseDownOnCrop = false
      return;
    }

    if (this.mouseDownOnCrop) {
      this.mouseDownOnCrop = false
      this.dragStarted = false

      onDragEnd?.(e)
      onComplete?.(convertToPixelCrop(crop, box.width, box.height), convertToPercentCrop(crop, box.width, box.height))

      this.setState({ cropIsActive: false, newCropIsBeingDrawn: false })
    }
  }

  onDragFocus = () => {
    // Fixes #491
    this.componentRef.current?.scrollTo(0, 0)
  }

  getCropStyle () {
    const { crop } = this.props

    if (!crop) {
      return undefined
    }

    return {
      top: `${crop.y}${crop.unit}`,
      left: `${crop.x}${crop.unit}`,
      width: `${crop.width}${crop.unit}`,
      height: `${crop.height}${crop.unit}`
    }
  }

  dragCrop () {
    const { evData } = this
    const box = this.getBox()
    const nextCrop = this.makePixelCrop()
    const xDiff = evData.clientX - evData.startClientX
    const yDiff = evData.clientY - evData.startClientY

    nextCrop.x = clamp(evData.startCropX + xDiff, 0, box.width - nextCrop.width)
    nextCrop.y = clamp(evData.startCropY + yDiff, 0, box.height - nextCrop.height)

    return nextCrop
  }

  getPointRegion (box: Rectangle): XYOrds {
    const { evData } = this
    const relativeX = evData.clientX - box.x
    const relativeY = evData.clientY - box.y
    console.log("relativeX", evData.clientX, evData.clientY);
    const topHalf = relativeY < evData.startCropY
    const leftHalf = relativeX < evData.startCropX

    if (leftHalf) {
      return topHalf ? 'nw' : 'sw'
    } else {
      return topHalf ? 'ne' : 'se'
    }
  }

  resizeCrop () {
    const { evData } = this
    const box = this.getBox()
    const { aspect = 0, minWidth = 0, minHeight = 0, maxWidth, maxHeight } = this.props
    const area = this.getPointRegion(box)
    const nextCrop = this.makePixelCrop()
    const resolvedOrd: Ords = evData.ord ? evData.ord : area
    const xDiff = evData.clientX - evData.startClientX
    const yDiff = evData.clientY - evData.startClientY

    const tmpCrop: PixelCrop = {
      unit: 'px',
      x: 0,
      y: 0,
      width: 0,
      height: 0
    }

    if (area === 'ne') {
      tmpCrop.x = evData.startCropX
      tmpCrop.width = xDiff

      if (aspect) {
        tmpCrop.height = tmpCrop.width / aspect
        tmpCrop.y = evData.startCropY - tmpCrop.height
      } else {
        tmpCrop.height = Math.abs(yDiff)
        tmpCrop.y = evData.startCropY - tmpCrop.height
      }
    } else if (area === 'se') {
      tmpCrop.x = evData.startCropX
      tmpCrop.y = evData.startCropY
      tmpCrop.width = xDiff

      if (aspect) {
        tmpCrop.height = tmpCrop.width / aspect
      } else {
        tmpCrop.height = yDiff
      }
    } else if (area === 'sw') {
      tmpCrop.x = evData.startCropX + xDiff
      tmpCrop.y = evData.startCropY
      tmpCrop.width = Math.abs(xDiff)

      if (aspect) {
        tmpCrop.height = tmpCrop.width / aspect
      } else {
        tmpCrop.height = yDiff
      }
    } else if (area === 'nw') {
      tmpCrop.x = evData.startCropX + xDiff
      tmpCrop.width = Math.abs(xDiff)

      if (aspect) {
        tmpCrop.height = tmpCrop.width / aspect
        tmpCrop.y = evData.startCropY - tmpCrop.height
      } else {
        tmpCrop.height = Math.abs(yDiff)
        tmpCrop.y = evData.startCropY + yDiff
      }
    }

    const containedCrop = containCrop(
      tmpCrop,
      aspect,
      area,
      box.width,
      box.height,
      minWidth,
      minHeight,
      maxWidth,
      maxHeight
    )

    // Apply x/y/width/height changes depending on ordinate
    // (fixed aspect always applies both).
    if (aspect || CustomCropperAndSelector.xyOrds.includes(resolvedOrd)) {
      nextCrop.x = containedCrop.x
      nextCrop.y = containedCrop.y
      nextCrop.width = containedCrop.width
      nextCrop.height = containedCrop.height
    } else if (CustomCropperAndSelector.xOrds.includes(resolvedOrd)) {
      nextCrop.x = containedCrop.x
      nextCrop.width = containedCrop.width
    } else if (CustomCropperAndSelector.yOrds.includes(resolvedOrd)) {
      nextCrop.y = containedCrop.y
      nextCrop.height = containedCrop.height
    }

    return nextCrop
  }

  createCropSelection () {
    const {
      ariaLabels = CustomCropperAndSelector.defaultProps.ariaLabels,
      disabled,
      locked,
      ruleOfThirds,
      crop
    } = this.props
    const style = this.getCropStyle()

    if (!crop) {
      return undefined
    }

    return (
      <div
        style={style}
        className="ReactCrop__crop-selection"
        onPointerDown={this.onCropPointerDown}
        aria-label={ariaLabels.cropArea}
        tabIndex={0}
        onKeyDown={this.onComponentKeyDown}
        role="group"
      >
        {!disabled && !locked && (
          <div className="ReactCrop__drag-elements" onFocus={this.onDragFocus}>
            <div className="ReactCrop__drag-bar ord-n" data-ord="n" />
            <div className="ReactCrop__drag-bar ord-e" data-ord="e" />
            <div className="ReactCrop__drag-bar ord-s" data-ord="s" />
            <div className="ReactCrop__drag-bar ord-w" data-ord="w" />

            <div
              className="ReactCrop__drag-handle ord-nw"
              data-ord="nw"
              tabIndex={0}
              aria-label={ariaLabels.nwDragHandle}
              onKeyDown={e => { this.onHandlerKeyDown(e, 'nw'); }}
              role="button"
            />
            <div
              className="ReactCrop__drag-handle ord-n"
              data-ord="n"
              tabIndex={0}
              aria-label={ariaLabels.nDragHandle}
              onKeyDown={e => { this.onHandlerKeyDown(e, 'n'); }}
              role="button"
            />
            <div
              className="ReactCrop__drag-handle ord-ne"
              data-ord="ne"
              tabIndex={0}
              aria-label={ariaLabels.neDragHandle}
              onKeyDown={e => { this.onHandlerKeyDown(e, 'ne'); }}
              role="button"
            />
            <div
              className="ReactCrop__drag-handle ord-e"
              data-ord="e"
              tabIndex={0}
              aria-label={ariaLabels.eDragHandle}
              onKeyDown={e => { this.onHandlerKeyDown(e, 'e'); }}
              role="button"
            />
            <div
              className="ReactCrop__drag-handle ord-se"
              data-ord="se"
              tabIndex={0}
              aria-label={ariaLabels.seDragHandle}
              onKeyDown={e => { this.onHandlerKeyDown(e, 'se'); }}
              role="button"
            />
            <div
              className="ReactCrop__drag-handle ord-s"
              data-ord="s"
              tabIndex={0}
              aria-label={ariaLabels.sDragHandle}
              onKeyDown={e => { this.onHandlerKeyDown(e, 's'); }}
              role="button"
            />
            <div
              className="ReactCrop__drag-handle ord-sw"
              data-ord="sw"
              tabIndex={0}
              aria-label={ariaLabels.swDragHandle}
              onKeyDown={e => { this.onHandlerKeyDown(e, 'sw'); }}
              role="button"
            />
            <div
              className="ReactCrop__drag-handle ord-w"
              data-ord="w"
              tabIndex={0}
              aria-label={ariaLabels.wDragHandle}
              onKeyDown={e => { this.onHandlerKeyDown(e, 'w'); }}
              role="button"
            />
          </div>
        )}
        {ruleOfThirds && (
          <>
            <div className="ReactCrop__rule-of-thirds-hz" />
            <div className="ReactCrop__rule-of-thirds-vt" />
          </>
        )}
      </div>
    )
  }

  makePixelCrop () {
    const crop = { ...defaultCrop, ...(this.props.crop || {}) }
    const box = this.getBox()
    return convertToPixelCrop(crop, box.width, box.height)
  }

  render () {
    const { aspect, children, circularCrop, className, crop, areas, disabled, locked, style, ruleOfThirds } = this.props
    const { cropIsActive, newCropIsBeingDrawn } = this.state
    const cropSelection = crop ? this.createCropSelection() : null

    const componentClasses = clsx('ReactCrop', className, {
      'ReactCrop--active': cropIsActive,
      'ReactCrop--disabled': disabled,
      'ReactCrop--locked': locked,
      'ReactCrop--new-crop': newCropIsBeingDrawn,
      'ReactCrop--fixed-aspect': crop && aspect,
      'ReactCrop--circular-crop': crop && circularCrop,
      'ReactCrop--rule-of-thirds': crop && ruleOfThirds,
      'ReactCrop--invisible-crop': !this.dragStarted && crop && !crop.width && !crop.height,
      'ReactCrop--no-animate': circularCrop
    })

    return (
      <div ref={this.componentRef} className={componentClasses} style={style}>
        <div ref={this.mediaRef} className="ReactCrop__child-wrapper" onPointerDown={this.onComponentPointerDown}>
          {children}
        </div>
        {cropSelection}
        {areas.map((area, index) => (
          <Area
            key={index}
            area={area}
            showHandles={!area.isNew}
            onCropStart={(event) => { this.onAreaPointerDown(event, index); }}
            areaNumber={index + 1}
          />
        ))}
      </div>
    )
  }
}
