import { createTheme, CssBaseline, Paper, responsiveFontSizes, ThemeProvider } from "@mui/material";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { RootStore } from "./store/RootStore";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

// const darkMainTheme = createTheme({
//   palette: {
//     mode: "dark",
//     primary: {
//       light: "#75CF63",
//       main: "#44A031",
//       dark: "#2D6920"
//     }
//   }
// });
let lightMainTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      light: "#b1e8ec",
      main: "#a9ffff",
      dark: "#89ffff"
    },
    secondary: {
      light: "#51c8d6",
      main: "#41ccd6",
      dark: "#0094a1"
    }
  },
  typography: {
    fontFamily: "'Lora', serif"
  }
});

lightMainTheme = responsiveFontSizes(lightMainTheme);

const rootStore: RootStore = new RootStore();
root.render(
  <ThemeProvider theme={lightMainTheme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <Paper>
    <BrowserRouter>
      <App store={rootStore}/>
    </BrowserRouter>
    </Paper>
  </ThemeProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
