import { ThemeProvider } from "@emotion/react";
import { Delete, Download } from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Card, CardMedia, Grid, IconButton, Modal, TextField, Typography, createTheme, responsiveFontSizes } from "@mui/material";
import { observer } from "mobx-react";
import React, { useContext, useState, type FC } from "react";
import { isMobileContext, isTabContext } from "src/contexts/rootContext";
import { type RootStore } from "src/store/RootStore";
import { type QRInfo } from "src/types/QRInfoTypes";

interface IQRInfoCardProps {
    store: RootStore,
    handleDeleteConfirm: React.MouseEventHandler<HTMLButtonElement>,
    qrInfo: QRInfo,
    postQRInfoGlobal: any
}

let redTheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        light: "#ff7070",
        main: "#ee4040",
        dark: "#cc0000"
      }
    },
    typography: {
      fontFamily: "'Lora', serif"
    }
  });
redTheme = responsiveFontSizes(redTheme);

export const QRInfoCard:FC<IQRInfoCardProps> = observer(({ store, handleDeleteConfirm, qrInfo, postQRInfoGlobal }:IQRInfoCardProps) => {
    console.log("qrId ", qrInfo.qrID);
    const [qrName, setQrName] = useState<string>(qrInfo.qrName ?? "");
    const [webpageURL, setWebpageURL] = useState<string>(qrInfo.websiteEndpoint ?? "");
    const [qrDescription, setQrDescription] = useState<string>(qrInfo.qrDescription ?? "");
    const [isNameEdit, setIsNameEdit] = useState<boolean>(false);
    const [isLinkEdit, setIsLinkEdit] = useState<boolean>(false);
    const [isDescriptionEdit, setIsDescriptionEdit] = useState<boolean>(false);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const handleDeleteClose = () => {
        setDeleteOpen(false)
    };
    const handleDeleteClick = () => {
        setDeleteOpen(true);
    }
    const handleDownloadClick = () => {
        void fetch(store.stageConfig.qrImageURLBase + String(qrInfo.qrImageURL ?? ""), { mode: 'cors' }).then(async (data) => await data.blob() ).then( async (imageBlob) => {
            const blobURL = URL.createObjectURL(imageBlob);
            const a = document.createElement("a");
            a.href = blobURL;
            a.download = String(qrInfo.qrName ?? "BeautifulQR") + ".jpg";
            document.body.appendChild(a);
            a.click();
        }).catch((e: any) => { console.log("Error ", e) });
    };
    const isMobile = useContext(isMobileContext);
    const isTab = useContext(isTabContext);
    const handleQRNameSave = () => {
        qrInfo.qrName = qrName;
        postQRInfoGlobal(qrInfo)
        setIsNameEdit(false)
    }
    const handleDescriptionSave = () => {
        qrInfo.qrDescription = qrDescription;
        postQRInfoGlobal(qrInfo)
        setIsDescriptionEdit(false);
    }
    const handleURLSave = () => {
        qrInfo.websiteEndpoint = webpageURL;
        postQRInfoGlobal(qrInfo)
        setIsLinkEdit(false)
    }

    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "85%",
        maxWidth: 1000,
        bgcolor: 'background.paper',
        border: '1px solid #bbbbbb',
        borderRadius: 3,
        p: 4
    };
    return (
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={6} sm={5} md={3.5} lg={2.5} sx={{ marginTop: 1, marginBottom: 1 }}>
                    <CardMedia
                        component="img"
                        image= { store.stageConfig.qrImageURLBase + String(qrInfo.qrImageURL ?? "") }
                        crossOrigin="anonymous"
                        alt="Paella dish"
                        sx={{ width: "100%", aspectRatio: 1, maxWidth: 280 }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={8.5} lg={9.5}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <Box >
                        {
                            isNameEdit &&
                            <Box sx={{ marginBottom: isTab ? 2 : "3%", marginTop: isTab ? 1 : 2, display: "flex", justifyContent: "center" }}>
                            <TextField
                            variant='standard'
                            value={qrName}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setQrName(event.target.value);
                            }}
                            sx={{ width: "50%", marginRight: 1, textAlign: "center" }}/>
                            <Button variant='outlined' color="secondary"
                            onClick={handleQRNameSave}>
                                Save
                            </Button>
                            </Box>
                        }
                        {
                            !isNameEdit &&
                            <Box sx={{ marginBottom: isTab ? 1 : "3%", marginTop: isTab ? 1 : 2, display: "flex", justifyContent: "center" }}>
                            <Typography variant={isMobile ? "body1" : "h6"} sx={{ marginRight: 1 }}>
                                {qrName}
                            </Typography>
                            <IconButton sx={{ marginTop: isMobile ? -1 : 0 }} onClick={() => { setIsNameEdit(true) }}>
                                <EditIcon fontSize={ isMobile ? 'small' : 'medium' }/>
                            </IconButton>
                            </Box>
                        }
                            <Typography variant={isMobile ? "body2" : "body1"} marginLeft={2} textAlign={"left"} >
                                Webpage Link
                            </Typography>
                            {
                                isLinkEdit &&
                                <Box sx={{ marginBottom: isMobile ? 1 : 2, display: "flex", marginLeft: 2 }}>
                                <TextField
                                variant='standard'
                                value={webpageURL}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setWebpageURL(event.target.value);
                                }}
                                multiline
                                maxRows={3}
                                sx={{ width: "80%", marginRight: 1, textAlign: "center" }}/>
                                <Button variant='outlined' color="secondary"
                                onClick={handleURLSave}>
                                    Save
                                </Button>
                                </Box>
                            }
                            {
                                !isLinkEdit &&
                                <Box sx={{ marginBottom: isMobile ? 1 : 2, marginLeft: 2, display: "flex" }}>
                                <Typography variant={isMobile ? "body2" : "body1"} sx={{ textAlign: "left", maxWidth: "80%", wordWrap: "break-word", marginRight: 1 }}>
                                    {webpageURL}
                                </Typography>
                                <IconButton sx={{ marginTop: -1 }} onClick={() => { setIsLinkEdit(true) }}>
                                    <EditIcon fontSize='small'/>
                                </IconButton>
                                </Box>
                            }
                            <Typography variant={isMobile ? "body2" : "body1"} marginLeft={2} textAlign={"left"}>
                                Description (Optional)
                            </Typography>
                            {
                                isDescriptionEdit &&
                                <Box sx={{ marginBottom: isMobile ? 0 : "3%", display: "flex", marginLeft: 2 }}>
                                <TextField
                                variant='standard'
                                value={qrDescription}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setQrDescription(event.target.value);
                                }}
                                multiline
                                maxRows={3}
                                sx={{ width: "80%", marginRight: 1, textAlign: "center" }}/>
                                <Button variant='outlined' color="secondary"
                                onClick={handleDescriptionSave}>
                                    Save
                                </Button>
                                </Box>
                            }
                            {
                                !isDescriptionEdit &&
                                <Box sx={{ marginBottom: isMobile ? 0 : "3%", marginLeft: 2, display: "flex" }}>
                                <Typography variant={isMobile ? "body2" : "body1"} sx={{ textAlign: "left", maxWidth: "80%", wordWrap: "break-word", marginRight: 1 }}>
                                    {qrDescription}
                                </Typography>
                                <IconButton sx={{ marginTop: -1 }} onClick={() => { setIsDescriptionEdit(true) }}>
                                    <EditIcon fontSize='small'/>
                                </IconButton>
                                </Box>
                            }
                        </Box>
                    </Box>
                    {
                        isMobile &&
                        <Box>
                            <IconButton
                                color="secondary"
                                onClick={ handleDownloadClick }
                                sx = {{ marginBottom: "2%", marginRight: 5 }}
                            >
                                <Download/>
                            </IconButton>
                            <ThemeProvider theme={redTheme}>
                            <IconButton
                                color="primary"
                                onClick={ handleDeleteClick }
                                sx = {{ marginBottom: "2%" }}
                            >
                                <Delete/>
                            </IconButton>
                            </ThemeProvider>
                        </Box>
                    }
                    {
                        !isMobile &&
                        <Box>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={ handleDownloadClick }
                                sx = {{ marginBottom: 1, marginRight: 5 }}
                            >
                                {"Download"}
                            </Button>
                            <ThemeProvider theme={redTheme}>
                            <Button
                                variant="outlined"
                                onClick={ handleDeleteClick }
                                sx = {{ marginBottom: 1 }}
                            >
                                {"Delete"}
                            </Button>
                            </ThemeProvider>
                        </Box>
                    }
                </Grid>
            </Grid>
            <Modal
            open={deleteOpen}
            aria-labelledby="modal-modal-Delete"
            >
            <Box sx={modalStyle}>
                <Typography variant="h6">
                    Are you sure you want to <Box component="span" fontWeight="bold">delete</Box> the below QR
                </Typography>
                <Card sx={{ boxShadow: 4, marginBottom: 2, marginTop: 2 }}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={6} sm={5} md={4} sx={{ marginTop: 1, marginBottom: 1 }}>
                            <CardMedia
                                component="img"
                                image= { store.stageConfig.qrImageURLBase + String(qrInfo.qrImageURL ?? "") }
                                alt="Paella dish"
                                sx={{ width: "100%", aspectRatio: 1 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <Box >
                                    <Typography variant={isTab ? isMobile ? "body2" : "body1" : "h6"} marginLeft={5} textAlign={"left"} >
                                        QR Name
                                    </Typography>
                                    <Box sx={{ marginBottom: isMobile ? 1 : 2, marginLeft: 5, display: "flex" }}>
                                    <Typography variant={isTab ? isMobile ? "body2" : "body1" : "h6"} sx={{ maxWidth: "80%", wordWrap: "break-word" }}>
                                        {qrName}
                                    </Typography>
                                    </Box>
                                    <Typography variant={isTab ? isMobile ? "body2" : "body1" : "h6"} marginLeft={5} textAlign={"left"} >
                                        Webpage Link
                                    </Typography>
                                    <Box sx={{ marginBottom: isMobile ? 1 : 2, marginLeft: 5, display: "flex" }}>
                                    <Typography variant={isTab ? isMobile ? "body2" : "body1" : "h6"} sx={{ maxWidth: "80%", wordWrap: "break-word" }}>
                                        {webpageURL}
                                    </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Card>
                <Box display={"flex"} justifyContent={"center"}>
                    <Button variant="contained" color="error" onClick={handleDeleteConfirm} sx={{ marginLeft: 2, marginRight: 2 }}>
                        Delete
                    </Button>
                    <Button variant="contained" onClick={handleDeleteClose} sx={{ marginLeft: 2, marginRight: 2 }}>
                        Close
                    </Button>
                    </Box>
                </Box>
            </Modal>
        </Card>
    )
})