import { Button, ButtonBase, Card, CardMedia, Container, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { observer } from "mobx-react";
import React, { useEffect, useState, type FC } from "react";
import { useNavigate } from 'react-router-dom';
import HomeNewsLetter from "src/components/Newsletter";
import { IMAGE_URI_BASE } from "src/constants/RootConstants";
import { isMobileContext } from "src/contexts/rootContext";
import { type RootStore } from "../store/RootStore";
// const reactHtml5Video = require('react-html5video');
// import QRCarousel from "src/components/QRCarousel";

interface ILandingPageProps {
    store: RootStore;
}
export const LandingPage:FC<ILandingPageProps> = observer(({ store }:ILandingPageProps) => {
    console.log(store.isLoggedIn)
    const navigate = useNavigate();
    const handleTryOutClick = () => {
        navigate('/qrgenworkflow/1');
        window.scrollTo(0, 0);
    }
    const handleContactUsClick = () => {
        navigate('/contactUs?page=0');
        window.scrollTo(0, 0);
    };
    const [windowSize, setWindowSize] = useState<number>(window.innerWidth);

    function handleWindowSizeChange () {
        setWindowSize(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        console.log(store.isLoggedIn)
        return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    useEffect(() => {
        const image = new Image();
        image.src = `${IMAGE_URI_BASE}headerBackground2Large.jpg`;
        image.crossOrigin = 'anonymous';
        void fetch(image.src, { mode: 'cors' })
    }, [])
    return (
        <isMobileContext.Consumer>
            { isMobile => (
                <Box>
                    {/* sx={{ backgroundImage: `url(${IMAGE_URI_BASE}headerBackground2Large.jpg)`, backgroundPosition: "center", backgroundSize: "2000px", position: "relative" }} */}
                    <Box sx={{ backgroundColor: "#060606" }} height={"103vh"} minHeight={660} maxHeight={1000}>
                    <Grid container spacing={windowSize < 900 ? 2 : 6} height={windowSize < 900 ? "inherit" : "100%"} justifyContent={"center"}>
                        <Grid item xs={12} sm={12} md={7} lg={6} sx={{ display: "flex", flexDirection: "column", justifyContent: windowSize < 900 ? "start" : "center", alignItems: windowSize < 900 ? "center" : "left" }} marginTop={ windowSize < 900 ? 0 : -10 } marginLeft={ windowSize < 900 ? 0 : 10 } marginRight={ windowSize < 900 ? 0 : -10 } paddingBottom={2}>
                            <Typography color={"#ffffff"} variant= { windowSize < 1500 ? windowSize < 400 ? "h5" : "h4" : "h3"} paddingTop={ windowSize < 900 ? 5 : 0 } paddingBottom={1} textAlign={windowSize < 900 ? "center" : "left"} marginLeft={ isMobile ? 1 : 0 }>
                                Why just black and white?
                            </Typography>
                            <Typography color={"#ffffff"} variant= { windowSize < 1500 ? windowSize < 400 ? "h5" : "h4" : "h3"} paddingBottom={2} textAlign={windowSize < 900 ? "center" : "left"} marginLeft={ isMobile ? 1 : 0 }>
                                Make your QRs colorful and live
                            </Typography>
                            <Typography color={"#c6c6c6"} variant= {windowSize < 400 ? "h6" : "h5" } paddingBottom={windowSize < 400 ? 0 : 1} textAlign={windowSize < 900 ? "center" : "left"} marginLeft={ isMobile ? 1 : 0 }>
                                For the first time ever
                            </Typography>
                            <Typography color={"#c6c6c6"} variant= {windowSize < 400 ? "h6" : "h5" } paddingBottom={2} textAlign={windowSize < 900 ? "center" : "left"} marginLeft={ isMobile ? 1 : 0 } marginBottom={ windowSize < 900 ? 1 : 5 }>
                                Create QRs with any image or video
                            </Typography>
                            {/* <Typography color={"#ffffff"} variant= {isMobile ? "h6" : "h5"} paddingBottom={2}>
                                Brand logo, product image or a picture which means everything to you
                            </Typography> */}
                            <Button variant="contained" color="secondary" onClick={ handleTryOutClick } sx={{ width: "fit-content" }}>
                                <Typography variant="h6" color={"#ffffff"}>
                                    Try for free
                                </Typography>
                            </Button>
                            {/* <QRImageAnimation/> */}
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} display={"flex"} justifyContent={windowSize < 900 ? "start" : "center"} alignItems={ windowSize < 900 ? "start" : "center"}>
                        <Container sx={{ width: windowSize < 600 ? 5 / 6 : windowSize < 900 ? 7 / 12 : 3 / 4, maxWidth: isMobile ? 350 : windowSize < 1000 ? 430 : 700, paddingBottom: windowSize < 900 ? "20%" : 0 }}>
                            {/* <video autoPlay playsInline loop muted width={"100%"}>
                                <source type={"video/webm"} src={`${IMAGE_URI_BASE}catDance2.webm`}></source>
                            </video> */}
                            {/* <VideoComponent videoSrc={`${IMAGE_URI_BASE}catDance2.webm`} videoType="video/webm"></VideoComponent> */}
                            <div dangerouslySetInnerHTML={{
                                __html: `
                                <video
                                loop
                                muted
                                autoplay
                                playsinline
                                src="${IMAGE_URI_BASE}catDance2.mp4"
                                style="width:100%"
                                />,`
                                }}>
                            </div>
                            {/* <reactHtml5Video.DefaultPlayer autoPlay loop muted
                                poster="http://sourceposter.jpg"
                                onCanPlayThrough={() => {
                                    // Do stuff
                                }}>
                                <source src="http://sourcefile.webm" type="video/webm" />
                            </reactHtml5Video.DefaultPlayer> */}
                        </Container>
                        </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{
                        backgroundColor: "#65cbe9"
                    // backgroundImage: "linear-gradient(to bottom, #057b89, #65cbe9 4%)",
                    // backgroundSize: "100% 100%",
                    // backgroundPosition: "50% 0%",
                    // backgroundRepeat: "no-repeat"
                    }}>
                        <Grid container spacing={"3%"} paddingTop={4} paddingBottom={8} paddingLeft={isMobile ? "5%" : windowSize < 900 ? "10%" : "5%"} justifyContent={"center"} alignItems={"center"}>
                            <Grid item xs={6} sm={6} md={3} lg={3} marginBottom={3}>
                            <Card sx={{ width: isMobile ? "90%" : "85%", maxHeight: isMobile ? 300 : 700 }}>
                            <ButtonBase>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Typography variant= { "h6" } marginTop={1} marginBottom={ 1 } >
                                        Brand Logo
                                    </Typography>
                                    <CardMedia
                                        component="img"
                                        image = {IMAGE_URI_BASE + "LogoStarbucksQR2.jpg"}
                                        crossOrigin='anonymous'
                                        alt="Paella dish"
                                        sx={{ width: "100%", margin: "auto" }}
                                    />
                                </Box>
                            </ButtonBase>
                            </Card>
                            </Grid>
                            <Grid item xs={6} sm={6} md={3} lg={3} marginBottom={3}>
                            <Card sx={{ width: isMobile ? "90%" : "85%", maxHeight: isMobile ? 300 : 700 }}>
                            <ButtonBase>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Typography variant= { "h6" } marginTop={1} marginBottom={ 1 } >
                                        Product Images
                                    </Typography>
                                    <CardMedia
                                        component="img"
                                        image = {IMAGE_URI_BASE + "ProductShoeQR.jpg"}
                                        crossOrigin='anonymous'
                                        alt="Paella dish"
                                        sx={{ width: "100%", margin: "auto" }}
                                    />
                                </Box>
                            </ButtonBase>
                            </Card>
                            </Grid>
                            <Grid item xs={6} sm={6} md={3} lg={3} marginBottom={3}>
                            <Card sx={{ width: isMobile ? "90%" : "85%", maxHeight: isMobile ? 300 : 700 }}>
                            <ButtonBase>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Typography variant= { "h6" } marginTop={1} marginBottom={ 1 } >
                                        Personal Profile
                                    </Typography>
                                    <CardMedia
                                        component="img"
                                        image = {IMAGE_URI_BASE + "profilePicQR2.jpg"}
                                        crossOrigin='anonymous'
                                        alt="Paella dish"
                                        sx={{ width: "100%", margin: "auto" }}
                                    />
                                </Box>
                            </ButtonBase>
                            </Card>
                            </Grid>
                            <Grid item xs={6} sm={6} md={3} lg={3} marginBottom={3}>
                            <Card sx={{ width: isMobile ? "90%" : "85%", maxHeight: isMobile ? 300 : 700 }}>
                            <ButtonBase>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Typography variant= { "h6" } marginTop={1} marginBottom={ 1 } >
                                        Events
                                    </Typography>
                                    <CardMedia
                                        component="img"
                                        image = {IMAGE_URI_BASE + "EventPosterQR.jpg"}
                                        crossOrigin='anonymous'
                                        alt="Paella dish"
                                        sx={{ width: "100%", margin: "auto" }}
                                    />
                                </Box>
                            </ButtonBase>
                            </Card>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{
                    // backgroundImage: isMobile ? "linear-gradient(to right, #40644e, #5ea0a0 3%, #70cde6 50%, #5ea0a0 97%, #40644e 100%)" : "linear-gradient(to right, #30543e, #59908a 5%, #70cde6 50%, #59908a 95%, #30543e 100%)",
                    backgroundImage: "linear-gradient(to bottom, #65cbe9, #ffe5e5 40%)",
                    backgroundSize: "100% 100%",
                    backgroundPosition: "50% 100",
                    backgroundRepeat: "no-repeat"
}}>
                        <Container disableGutters={!isMobile}>
                        <Grid container paddingTop={0} paddingBottom={4} spacing={isMobile ? 2 : "5%"}>
                            <Grid item xs={12} sm={4} >
                                <img src = {`${IMAGE_URI_BASE}MessiQR2.jpg`} width={isMobile ? "80%" : "90%"} style={isMobile ? {} : { marginTop: 40 }}/>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="h4" align={ isMobile ? "center" : "left" } paddingTop={isMobile ? 0 : "10%"} paddingBottom={1}>
                                    Create QRs with your favourite images
                                </Typography>
                                <Typography variant="h5" align="left" paddingLeft={"1%"} paddingBottom={2}>
                                    Make QRs with minimal modifications to the image while retaining quality with the power of AI. Supports 4k quality images.
                                </Typography>
                                <Button variant="contained" color="primary" onClick={ handleTryOutClick }>
                                <Typography variant="h6">
                                    Try for free
                                </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container paddingTop={4} paddingBottom={4} spacing={isMobile ? 2 : "5%"}>
                            {
                                isMobile &&
                                <Grid item xs={12} sm={4} >
                                    <img crossOrigin = "anonymous" src = {`${IMAGE_URI_BASE}QRManagement.jpg`} width={"80%"}/>
                                </Grid>
                            }
                            <Grid item xs={12} sm={8}>
                                <Typography variant="h4" align={ isMobile ? "center" : "left" } paddingLeft={isMobile ? 0 : "5%"} paddingTop={isMobile ? 0 : "5%"} paddingBottom={1}>
                                    QR Management Platform
                                </Typography>
                                <Typography variant="h5" align="left" paddingLeft={isMobile ? 0 : "6%"} paddingBottom={2}>
                                    End to end dynamic QR management platform for easy bulk QR generation and management.
                                </Typography>
                                <Button variant="contained" color="primary" onClick={ handleTryOutClick }>
                                    <Typography variant="h6">
                                        Try for free
                                    </Typography>
                                </Button>
                            </Grid>
                            {
                                !isMobile &&
                                <Grid item xs={12} sm={4} >
                                    <img crossOrigin = "anonymous" src = {`${IMAGE_URI_BASE}QRManagement.jpg`} width={"100%"} style={{ marginTop: 40 }}/>
                                </Grid>
                            }
                        </Grid>
                        <Grid container paddingTop={4} paddingBottom={4} spacing={isMobile ? 2 : "5%"}>
                            <Grid item xs={12} sm={4} >
                                {
                                    isMobile &&
                                    <div dangerouslySetInnerHTML={{
                                        __html: `
                                        <video
                                        loop
                                        muted
                                        autoplay
                                        playsinline
                                        src="${IMAGE_URI_BASE}CokeQRVideo.mp4"
                                        style="width:80%;"
                                        />,`
                                        }}>
                                    </div>
                                }
                                {
                                    !isMobile &&
                                    <div dangerouslySetInnerHTML={{
                                        __html: `
                                        <video
                                        loop
                                        muted
                                        autoplay
                                        playsinline
                                        src="${IMAGE_URI_BASE}CokeQRVideo.mp4"
                                        style="width:90%; margin-top: 40px;"
                                        />,`
                                        }}>
                                    </div>
                                }
                                {/* <video autoPlay playsInline loop muted width={isMobile ? "80%" : "90%"} style={isMobile ? {} : { marginTop: 40 }}>
                                    <source type={"video/webm"} src={`${IMAGE_URI_BASE}CokeQRVideo.webm`}></source>
                                </video> */}
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="h4" align={ isMobile ? "center" : "left" } paddingTop={isMobile ? 0 : "10%"} paddingBottom={1}>
                                    QRs That Move
                                </Typography>
                                <Typography variant="h5" align="left" paddingLeft={"1%"} paddingBottom={2}>
                                    Custom made video QRs for catchy advertisements on digital platforms like Billboards, TV, Youtube etc.
                                </Typography>
                                <Button variant="contained" color="primary" onClick={ handleContactUsClick }>
                                    <Typography variant="h6">
                                        Contact Us
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container paddingTop={4} paddingBottom={8} spacing={isMobile ? 2 : "5%"}>
                            {
                            isMobile &&
                            <Grid item xs={12} sm={4} >
                                <img crossOrigin = "anonymous" src = {`${IMAGE_URI_BASE}Analytics.jpg`} width={isMobile ? "80%" : "100%"} style={isMobile ? {} : { marginTop: 60 }}/>
                            </Grid>
                            }
                            <Grid item xs={12} sm={8}>
                                <Typography variant="h4" align={ isMobile ? "center" : "left" } paddingTop={isMobile ? 0 : "10%"} paddingBottom={1}>
                                    Data collection and analytics platform
                                </Typography>
                                <Typography variant="h5" align="left" paddingLeft={"1%"} paddingBottom={2}>
                                    Visualise key metrics like number of scans, device type, user location and more.
                                </Typography>
                                <Button variant="contained" color="primary" >
                                    <Typography variant="h6">
                                        Coming Soon!!!
                                    </Typography>
                                </Button>
                            </Grid>
                            {
                            !isMobile &&
                            <Grid item xs={12} sm={4} >
                                <img crossOrigin = "anonymous" src = {`${IMAGE_URI_BASE}Analytics.jpg`} width={isMobile ? "80%" : "100%"} style={isMobile ? {} : { marginTop: 60 }}/>
                            </Grid>
                            }
                        </Grid>
                        </Container>
                    </Box>
                    <HomeNewsLetter/>
                    {/* <Box sx={{ backgroundColor: "#bbffff" }}>
                        <QRCarousel/>
                    </Box> */}
                </Box>
            )}
        </isMobileContext.Consumer>
      );
}
)