import { makeAutoObservable, runInAction } from "mobx";
import { deleteQRForCustomer, getQRInfoForCustomer, postQRFromTemplate, postQRInfoForCustomer } from "src/api/image2QR";
import { getCurrentStageConfig, type StageConfig } from "src/api/stageConfig";
import { type CustomerQRInfo, type QRInfo } from "src/types/QRInfoTypes";

export class RootStore {
  public isLoggedIn: boolean;
  public emailID: string;
  public customerQRInfo: CustomerQRInfo;
  public stageConfig: StageConfig;
  public showLimitReachedModal: boolean;
  public limitReachedModalMessage: string | null;
  constructor () {
    makeAutoObservable(this);
    this.isLoggedIn = false;
    this.emailID = "";
    this.customerQRInfo = { qrList: [], noOfActiveQR: 0, maxNoOfActiveQR: 3 };
    this.stageConfig = getCurrentStageConfig();
    this.showLimitReachedModal = false;
    this.limitReachedModalMessage = null;
  }

  getCustomerQRInfo = async () => {
    const qrData = await getQRInfoForCustomer();
    try {
      runInAction(() => {
        this.customerQRInfo = qrData.data;
      }
    );
    console.log(qrData.data);
    } catch (e) {
      console.log("Error occured", e);
    }
    }

    async deleteCustomerQR (qrID: string) {
      await deleteQRForCustomer(qrID).then(async () => {
        await this.getCustomerQRInfo();
      });
    }

  async postQRFromTemplate (qrInfo: QRInfo) {
    await postQRFromTemplate(qrInfo).then(async () => {
      await this.getCustomerQRInfo();
    });
  }

  async postQRInfoGlobal (qrInfo: QRInfo) {
    await postQRInfoForCustomer(qrInfo).then(async () => {
      const qrData = await getQRInfoForCustomer();
      try {
        runInAction(() => {
          this.customerQRInfo = qrData.data;
        }
      );
      console.log(qrData.data);
      } catch (e) {
        console.log("Error occured", e);
      }
    });
  }
}