import { EmailOutlined } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import * as React from "react";
import XLogo from "../images/X.svg";
import InstaLogo from "../images/insta.svg";
import LinkedInLogo from "../images/linkedIn.svg";

export default function Footer () {
  return (
    <Box
      component="footer"
      sx={{ borderTop: 1, borderColor: "#808080", borderRadius: 2, backgroundColor: "#bbffff" }}
    >
      <Box marginTop={2} marginBottom={1} flexDirection={"row"} sx={{ display: 'flex', justifyContent: 'space-evenly', alignContent: 'center' }}>
        <Box flexDirection={"row"} sx={{ display: 'inline-flex', justifyContent: "center", alignItems: 'center' }} >
          <EmailOutlined fontSize="large" sx={{ alignSelf: "center", marginRight: 2 }}/>
          <Typography fontSize={{ sm: 17, md: 19 }}>
            <Link href="mailto: help@theqrstore.in" color="inherit">help@theqrstore.in</Link>
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: "row", paddingRight: 3, justifyContent: "center", alignItems: "center" }} >
          <Typography variant = "h6" marginRight={"1.5vw"}>
            {"Follow Us On"}
          </Typography>
          <Stack display="inline" direction="row" spacing={"1vw"} marginTop={1}>
            <a href='https://www.instagram.com/the.qrstore'>
              <img src={InstaLogo} style={{ height: 45 }}/>
            </a>
            <a href=''>
              <img src={LinkedInLogo} style={{ height: 45 }}/>
            </a>
            <a href='https://twitter.com/theqrstore'>
              <img src={XLogo} style={{ height: 45 }}/>
            </a>
          </Stack>
        </Box>
        {/* <Box sx={{ display: 'inline-flex', paddingRight: 7 }} flexDirection={"column"}>
          <LocalPhoneOutlined sx={{ alignSelf: "center" }}/>
          <Typography>
            +91-8129133473
          </Typography>
        </Box> */}
      </Box>
      <Container maxWidth="sm">
        <Typography variant="body2" color="text.secondary">
          <Link color="inherit" href="https://theqrstore.in/">
            The QR Store
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Container>
    </Box>
  );
}