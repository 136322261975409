import { Box, Button, Container, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React, { useContext, useState, type FC } from "react";

// import { useNavigate } from "react-router-dom";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { isMobileContext } from "src/contexts/rootContext";
import { Image2QRStore } from "src/store/Image2QRStore";
import { type RootStore } from "src/store/RootStore";
import { AddURLPage } from "./AddURL";
import { Image2QRPage } from "./Image2QR";
import { ImageUploadPage } from "./ImageUpload";
// import { autorun } from "mobx";

interface IQRGenWorkflowPageProps {
    store: RootStore;
}

export const QRGenWorkflowPage:FC<IQRGenWorkflowPageProps> = observer(({ store }: IQRGenWorkflowPageProps) => {
    const params = useParams();
    const pageNumber = params["*"] ?? '0';
    const [image2QRStore] = useState(() => new Image2QRStore(store));
    const navigate = useNavigate();
    const isMobile = useContext(isMobileContext);
    console.log(store.isLoggedIn);
    // const handleNextClick = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // };

    const handleStepClick = (index: number) => {
        if (index < Number(pageNumber) - 1) {
            navigate(`/qrgenworkflow/${index + 1}`);
        }
    };

    // autorun(() => { console.log("Base", image2QRStore.base64EncodedImage); })

    // const handlePrevClick = () => {
    //     if (activeStep > 0) {
    //         setActiveStep((prevActiveStep) => prevActiveStep - 1);
    //     } else {
    //         navigate('/');
    //     }
    // };
    const steps = ['Upload Image', isMobile ? 'Create QR' : 'Create Your QR', isMobile ? 'Enter URL' : 'Enter Your URL'];
    return (
        <Box>
            <Box sx = {{ width: "100%", paddingTop: 2, paddingBottom: 2, background: "linear-gradient(to bottom, #30946e, #ffffff 1%)", display: "flex", boxShadow: 2 }}>
                {/* <IconButton onClick={handlePrevClick} sx={{ justifyContent: "center", marginLeft: isMobile ? 0 : 4, marginRight: isMobile ? -3 : -3, transform: isMobile ? "scale(1.0)" : "scale(1.3)" }}>
                    <ArrowBackIcon sx={{ color: "#000000" }}/>
                </IconButton> */}
                <Container sx = {{ paddingTop: 0.5 }}>
                <Stepper activeStep={Number(pageNumber) - 1}>
                {steps.map((label, index) => (
                    <Step key={label} sx={{
                        '& .MuiStepLabel-root .Mui-completed': {
                          color: 'secondary.dark', // circle color (COMPLETED)
                          transform: isMobile ? 'scale(1.05)' : 'scale(1.15)'
                        },
                        '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-label':
                        {
                            color: '#161616', // Just text label (COMPLETED)
                            transform: 'scale(1.0)'
                        },
                        '& .MuiStepLabel-root .Mui-active': {
                            color: 'secondary.main', // circle color (COMPLETED)
                            transform: isMobile ? 'scale(1.07)' : 'scale(1.2)'
                        },
                        '& .MuiStepLabel-label.Mui-active.MuiStepLabel-label': {
                            color: '#161616', // Just text label (ACTIVE)
                            transform: 'scale(1.0)'
                        },
                        '& .MuiStepLabel-root .Mui-disabled': {
                            color: '#161616',
                            transform: isMobile ? 'scale(1.1)' : 'scale(1.35)'
                        },
                        '& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-label': {
                            color: '#161616', // Just text label (ACTIVE)
                            transform: 'scale(1.0)'
                        },
                        paddingLeft: isMobile ? 0 : "auto",
                        paddingRight: isMobile ? 0 : "auto"
                    }}>
                    <Button variant="text" onClick={() => { handleStepClick(index) }}>
                    <StepLabel>{
                        <Typography variant={isMobile ? "body1" : "h6"}>
                            {label}
                        </Typography>
                    }</StepLabel>
                    </Button>
                    </Step>
                ))}
                </Stepper>
                </Container>
            </Box>
            <Routes>
                <Route path={`1`} element={<ImageUploadPage store={image2QRStore}/>}/>
                <Route path={`2`} element={<Image2QRPage store={image2QRStore}/>}/>
                <Route path={`3`} element={<AddURLPage store={image2QRStore}/>}/>
            </Routes>
        </Box>
    )
})