import {
    Box,
    Collapse,
    Container, createTheme,
    Grid, Link, Paper, ThemeProvider, Typography
} from "@mui/material";
import { observer } from "mobx-react";
import React, { useContext, useEffect, useState, type FC } from "react";
import { TemplateCreator } from "../components/TemplateCreator";
import { type Image2QRStore } from "../store/Image2QRStore";
// import { isMobileContext } from "src/contexts/rootContext";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { BallTriangle } from "react-loader-spinner";
import { ImageQRContainer } from "src/components/ImageQRContainer";
import { isMobileContext, isTabContext } from "src/contexts/rootContext";

interface IImage2QRPageProps {
    store: Image2QRStore;
}

export const Image2QRPage:FC<IImage2QRPageProps> = observer(({ store }:IImage2QRPageProps) => {
    const lightTheme = createTheme({
        palette: {
        mode: "light",
        primary: {
            light: "#81c8e2",
            main: "#51c8e2",
            dark: "#21b8de"
        },
        secondary: {
            light: "#51c8d6",
            main: "#41ccd6",
            dark: "#0094a1"
          }
        }
    });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const isTab = useContext(isTabContext);
    const isMobile = useContext(isMobileContext);
    // console.log("store1base64Image", store.base64EncodedImage);
    const [showNormalUserInfo, setShowNormalUserInfo] = useState<boolean>(false);
    const [showAdvanceUserInfo, setShowAdvanceUserInfo] = useState<boolean>(false);
    const [showImageGuidelines, setShowImageGuidelines] = useState<boolean>(false);

    useEffect(() => {
        store.qrInfo.qrID = sessionStorage.getItem("the_qr_store_qr_id") ?? "";
        store.getQRInfo()
        .then(() => {
          console.log("qrInfoVal " + JSON.stringify(store.qrInfo));
          store.getBase64FromSrcWithCors(store.rootStore.stageConfig.imageURLBase + (store.qrInfo.imageURL ?? ""));
          setIsLoading(false);
        })
        .catch((e) => { console.log(e) });
      }, []);

    return (
        <Box>
        {
            isLoading &&
            <Box minHeight={"80vh"} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Box width={"50%"} maxWidth={300}>
            <BallTriangle
                height={"100%"}
                width={"100%"}
                radius={"8%"}
                color="#0099aa"
                visible={true}
                />
            </Box>
            </Box>
        }
        {
            !isLoading &&
            <Box sx={{ backgroundColor: "#ffffff" }}>
             <Container sx={{ paddingTop: 2, marginTop: 2, paddingBottom: 2, marginBottom: 10, boxShadow: isMobile ? 0 : 3, borderRadius: 5 }} maxWidth= {"xl"}>
                <Grid container spacing={{ xs: 3, lg: 8 }}>
                <Grid item xs={12} sm={6}>
                    <ThemeProvider theme={lightTheme}>
                    <Paper>
                        <Container sx={{ paddingTop: 1, paddingBottom: 1 }}>
                        <Container sx={{ width: "100%" }}>
                            <TemplateCreator store={ store }/>
                        </Container>
                        </Container>
                    </Paper>
                    </ThemeProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ThemeProvider theme={lightTheme}>
                    <Paper>
                    <ImageQRContainer store={ store} setLoader = {setIsLoading}/>
                    </Paper>
                    </ThemeProvider>
                </Grid>
                </Grid>
            </Container>
            <Box sx={{ boxShadow: 2, padding: 3, paddingBottom: isTab ? isMobile ? 5 : "14vh" : 10 }}>
                <Typography variant="h5" marginBottom={2}>
                    QR Generation Guidelines
                </Typography>
                <Box onClick={() => { setShowNormalUserInfo(!showNormalUserInfo) }} sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                <Typography variant="h6" textAlign={"left"} marginBottom={1} marginRight={1}>
                    For Normal Use
                </Typography>
                    {showNormalUserInfo ? <ExpandLess sx={{ flexShrink: 1 }}/> : <ExpandMore sx = {{ flexShrink: 1 }}/>}
                </Box>
                <Collapse in={showNormalUserInfo} timeout="auto" unmountOnExit>
                <Typography variant= { "body1" } textAlign={"left"} marginBottom={1}>
                    { "A higher 'Scanning strength' ensures faster and easier scans with larger dot sizes. Our recommended scanning strength for most use cases is '2'."}
                </Typography>
                <Typography variant= { "body1" } textAlign={"left"} marginBottom={1}>
                    <li>{"Scanning Strength Level 1 - For QRs printed on at least 8*8 cm surfaces and supported with specific scanning apps. Although scans via apps like Google lens and specialised QR scanning apps can scan these QRs, the in-built camera scanner may not give the desired ease of scan."}</li>
                    <li>{"Scanning Strength Level 2 - For QRs printed on at least 5*5 cm surfaces. Easily scanned by apps like google lens and in-built camera."}</li>
                    <li>{"Scanning Strength Level 3 - For QRs printed on at least 3*3 cm surfaces. Easily scanned by apps like google lens and in-built camera."}</li>
                    <li>{"Scanning Strength Level 4 - For QRs printed on surfaces smaller than 3*3. Easily scanned by apps like google lens and in-built camera."}</li>
                </Typography>
                </Collapse>
                <Box onClick={() => { setShowAdvanceUserInfo(!showAdvanceUserInfo) }} sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                <Typography variant="h6" textAlign={"left"} marginBottom={1} marginRight={1}>
                    For Advance Use
                </Typography>
                    {showAdvanceUserInfo ? <ExpandLess sx={{ flexShrink: 1 }}/> : <ExpandMore sx = {{ flexShrink: 1 }}/>}
                </Box>
                <Collapse in={showAdvanceUserInfo} timeout="auto" unmountOnExit>
                <Typography variant= { "body1" } textAlign={"left"} marginBottom={1}>
                    Select Area Functionality - Our algorithm automatically tries to find the important parts of the image and minimize the dots in those regions. You can toggle to this option if you want to select important areas manually.
                </Typography>
                <Typography variant= { "body1" } textAlign={"left"} marginBottom={1}>
                    Clear Dots - This value is represented as the number of dots cleared from the important parts of the image as a percentage of the total number of dots that can be cleared, while maintaining its scannability. The total number of dots that can be cleared depends on the Error Correction (ECC) level.
                </Typography>
                <Typography variant= { "body1" } textAlign={"left"} marginBottom={1}>
                    Dot Size - Increasing dot size increases the scannability of the QR at the cost of higher disturbance to the original image.
                </Typography>
                <Typography variant= { "body1" } textAlign={"left"} marginBottom={1}>
                    {"ECC - Error Correction Level - This determines the redundancy built into the QR. If some data is missing or misread, the QR may still be detected correctly."}
                    <ul>
                        <li>L - 7% missing data</li>
                        <li>M - 15% missing data</li>
                        <li>Q - 25% missing data</li>
                        <li>H- 30% missing data</li>
                    </ul>
                    {"Note: More the ECC level, more the number of dots that will be cleared by the same level of 'Clear Dots' option"}
                </Typography>
                <Typography variant= { "body1" } textAlign={"left"} marginBottom={1}>
                    Blend - Determines how much the dots blend with the image resulting in prettier QRs. A low bend value conversely results in higher contrast, increasing scannability in poorly lit conditions.
                </Typography>
                <Typography variant= { "body1" } textAlign={"left"} marginBottom={1}>
                Version - This signifies the version of the underlying QR (See here). (See <Link href="https://www.qrcode.com/en/about/version.html" rel="noopener noreferrer" target="_blank" color={"inherit"}>here</Link>){". In our case, a 'version 5' will result in the markers in the corners becoming bigger but with less number of dots. 'Version 6' will result in smaller corner markers but with more dots."}
                </Typography>
                </Collapse>
                <Box onClick={() => { setShowImageGuidelines(!showImageGuidelines) }} sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                    <Typography variant={"h6"} marginRight={1}>
                        Image Guidelines
                    </Typography>
                    {showImageGuidelines ? <ExpandLess sx={{ flexShrink: 1 }}/> : <ExpandMore sx = {{ flexShrink: 1 }}/>}
                </Box>
                <Collapse in={showImageGuidelines} timeout="auto" unmountOnExit>
                    <Typography textAlign={"left"} variant="h6" marginLeft={1} marginTop={1} marginBottom={-1}>
                        General Instructions
                    </Typography>
                    <Typography textAlign={"left"} variant="subtitle1">
                    <ul>
                        <li>Minimal text and human faces give better QRs (We don’t want polka dotted humans!)</li>
                    </ul>
                    </Typography>
                    <Typography textAlign={"left"} variant="h6" marginLeft={1}>
                        For brand logos
                    </Typography>
                    <Typography textAlign={"left"} variant="subtitle1" marginLeft={3}>
                        Ideal logo length/width should be 50% of the total image length width of image uploaded. (This minimises dots on the main logo)
                    </Typography>
                    <Typography textAlign={"left"} variant="h6" marginLeft={1} marginTop={1}>
                        For profile pictures
                    </Typography>
                    <Typography textAlign={"left"} variant="subtitle1" marginLeft={3} marginBottom={2}>
                        Add sufficient background to your picture (to avoid dots on your face).
                    </Typography>
                </Collapse>
            </Box>
            </Box>
        }
        </Box>
        )
    }
)