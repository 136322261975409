import {
  EmailOutlined
} from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import * as React from "react";
import XLogo from "../images/X.svg";
import InstaLogo from "../images/insta.svg";
import LinkedInLogo from "../images/linkedIn.svg";

export default function FooterForMobile () {
  return (
    <Box
      component="footer"
      sx={{ borderTop: 1, borderColor: "#808080", borderRadius: 2, backgroundColor: "#bbffff" }}
    >
      <Box marginTop={1} marginBottom={1} flexDirection={"column"} sx={{ display: 'flex', justifyContent: 'space-evenly', alignContent: 'center', minHeight: 110 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center" }} flexDirection={"row"}>
          <Typography sx={{ marginRight: 3 }}>
            {"Follow Us On"}
          </Typography>
          <Stack display="inline" direction="row" spacing={2} sx={{ marginRight: 4 }} >
            <a href='https://www.instagram.com/the.qrstore'>
              <img src={InstaLogo} style={{ height: 35 }}/>
            </a>
            <a href=''>
              <img src={LinkedInLogo} style={{ height: 35 }}/>
            </a>
            <a href='https://twitter.com/theqrstore'>
              <img src={XLogo} style={{ height: 35 }}/>
            </a>
          </Stack>
        </Box>
        <Box flexDirection={"row"} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', marginBottom: 1 }} >
          <EmailOutlined sx={{ alignSelf: "center", marginRight: 6, marginLeft: 3 }}/>
          <Typography>
            <Link href="mailto: help@theqrstore.in" color="inherit">help@theqrstore.in</Link>
          </Typography>
        </Box>
        {/* <Box sx={{ display: 'flex', paddingRight: 6, justifyContent: 'space-evenly' }} flexDirection={"row"}>
          <LocalPhoneOutlined sx={{ alignSelf: "center", marginRight: 2 }}/>
          <Typography>
            +91-8129133473
          </Typography>
        </Box> */}
      </Box>
      <Container maxWidth="sm">
        <Typography variant="body2" color="text.secondary">
          <Link color="inherit" href="https://theqrstore.in/">
            The QR Store
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Container>
    </Box>
  );
}