import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
    Box,
    Button,
    Collapse,
    Container,
    Grid,
    ImageList,
    ImageListItem,
    Typography
} from "@mui/material";
import { observer } from "mobx-react";
import React, { useContext, useRef, useState, type FC } from "react";
// import { isMobileContext } from "src/contexts/rootContext";
import { useDropzone } from "react-dropzone";
import { BallTriangle } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { QRImageList } from "src/constants/RootConstants";
import { isMobileContext, isTabContext } from "src/contexts/rootContext";
import { type Image2QRStore } from "src/store/Image2QRStore";
// import { autorun } from "mobx";
// import { runInAction } from "mobx";

interface IImageUploadPageProps {
    store: Image2QRStore;
}

export const ImageUploadPage:FC<IImageUploadPageProps> = observer(({ store }:IImageUploadPageProps) => {
    // const lightTheme = createTheme({
    //     palette: {
    //     mode: "light",
    //     primary: {
    //         light: "#81c8e2",
    //         main: "#51c8e2",
    //         dark: "#21b8de"
    //     }
    //     }
    // });
    // const isMobile = useContext(isMobileContext);
    const [imageReceived, setImageReceived] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showGuidelines, setShowGuidelines] = useState<boolean>(true);
    // autorun(() => { console.log("In uplooad ", store.base64EncodedImage) });

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: { 'image/*': [] },
        onDrop: acceptedFiles => {
            // if(image){URL.revokeObjectURL(image)}
            store.imageFileUpload(acceptedFiles[0]);
            setImageReceived(true);
        },
        multiple: false,
        maxFiles: 1,
        noClick: imageReceived
    });
    const isMobile = useContext(isMobileContext);
    const isTab = useContext(isTabContext);
    const navigate = useNavigate();
    const dragZoneStyle = {
        width: "100%",
        height: "100%",
        border: isDragActive ? 1 : 0,
        borderColor: "primary.main",
        borderRadius: 5,
        boxShadow: 1,
        position: "relative"
    }

    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const handleFileClick = () => {
        console.log("Clicked");
        hiddenFileInput?.current?.click();
        console.log("Clicked");
    };

    const handleNextClick = async () => {
        setIsLoading(true);
        await store.initializeQRInfo();
        window.scrollTo(0, 0);
        sessionStorage.setItem("the_qr_store_qr_id", store.qrInfo.qrID);
        navigate('/qrgenworkflow/2');
        window.scrollTo(0, 0);
        setIsLoading(false);
    };

    return (
        <Box>
            {
                isLoading &&
                <Box minHeight={"80vh"} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <Box width={"50%"} maxWidth={300}>
                <BallTriangle
                    height={"100%"}
                    width={"100%"}
                    radius={"8%"}
                    color="#0099aa"
                    visible={true}
                    />
                </Box>
                </Box>
            }
            {!isLoading &&
                <Box sx={{ background: "linear-gradient(to bottom, #30745e, #ffffff 45px)", display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                <Grid container spacing={isTab ? 2 : 0} sx = {{ paddingBottom: isTab ? 0 : 5 }} >
                { !isTab &&
                    <Grid item xs={12} sm={12} md={3} >
                        <Box sx={{ paddingTop: "1%", backgroundColor: "#362626", boxShadow: 5 }}>
                            <Typography textAlign={"left"} variant={"h5"} marginLeft={1} color={"#ffffff"} >
                                {"Don't have an image? Choose one from here!"}
                            </Typography>
                            <ImageList variant = {isTab ? "masonry" : "standard"} cols={2} gap={10} sx = {{ height: isTab ? 150 : "80vh" }}>
                            {QRImageList.map((image, index) => (
                                <ImageListItem key={image.thumbnail.uri + index.toString()} >
                                    <img
                                    crossOrigin = "anonymous"
                                    srcSet={`${image.thumbnail.uri}`}
                                    src={`${image.thumbnail.uri}`}
                                    style={{ borderRadius: 3 }}
                                    alt="BeautifulQRs" loading="lazy" onClick={ (e: any) => { setImageReceived(true); store.getBase64FromSrcWithCors(e.target.getAttribute('src')) }}/>
                                </ImageListItem>))
                            }
                            </ImageList>
                        </Box>
                    </Grid>
                }
                <Grid item xs={12} sm={12} md={6}>
                    <Box style={{ position: "relative" }}>
                        <Container sx={{ paddingTop: 2, paddingBottom: 2, width: "100%", boxShadow: 3, backgroundColor: "#ffffff" }}>
                            <Typography textAlign={"left"} marginBottom={2} marginLeft={1} variant="h5">
                                Upload Your Image Here
                            </Typography>
                            <Container {...getRootProps({ className: 'dropzone' })} disableGutters sx={{ display: "flex", justifyContent: "center" }}>
                            <input {...getInputProps()} ref={hiddenFileInput}/>
                            {!imageReceived &&
                            <Box sx={{ borderRadius: 5, width: isTab ? isMobile ? "100%" : "80%" : "100%", height: isTab ? isMobile ? "90vw" : "75vw" : "67vh", marginBottom: 2, minHeight: 300, backgroundColor: "#f2f2f2" }}>
                                <Container sx={dragZoneStyle}>
                                <Typography variant={"h6"} padding={1} sx={{ minWidth: "90%", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                    Click Here 👇 or Drag & Drop To Upload Image
                                </Typography>
                                </Container>
                            </Box>
                            }
                            {imageReceived &&
                            <Container sx={{ width: isTab ? isMobile ? "100%" : "80%" : "100%", paddingLeft: 1, paddingRight: 1, boxShadow: 3, marginBottom: 2, paddingTop: 1, paddingBottom: 1, height: isTab ? isMobile ? "90vw" : "75vw" : "67vh", minHeight: 300, backgroundColor: "#f2f2f2", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <img src={store.base64EncodedImage} style={{ margin: "auto", maxWidth: "100%", maxHeight: "100%" }}/>
                            </Container>
                            }
                            </Container>
                            <Container>
                            <Button
                                variant="outlined"
                                onClick={handleFileClick}
                                color="secondary"
                            >
                                {"Import"}
                            </Button>
                            <input
                                type="file"
                                onChange={(e: any) => { console.log("Image Changed"); setImageReceived(true); store.imageFileUpload(e.target.files[0]) }}
                                ref={hiddenFileInput}
                                accept="image/*"
                                style={{ display: 'none' }}
                            />
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => { void handleNextClick(); }}
                                sx = {{ marginLeft: 3 }}
                                disabled = { !imageReceived }
                            >
                                {"Next"}
                            </Button>
                            </Container>
                        </Container>
                    </Box>
                </Grid>
                { isTab &&
                    <Grid item xs={12} sm={12} md={6} >
                        <Box sx={{ paddingTop: "1%", backgroundColor: "#362626", boxShadow: 5 }}>
                            <Typography variant={"h6"} color="#ffffff">
                                {"Don't have an image? Choose one from here!!!"}
                            </Typography>
                            <ImageList variant = {isTab ? "masonry" : "standard"} cols={5} gap={7} sx = {{ height: isTab ? isMobile ? 170 : 340 : "80vh", marginX: 1 }}>
                            {QRImageList.map((image, index) => (
                                <ImageListItem key={image.thumbnail.uri + index.toString()}>
                                    <img
                                    crossOrigin = "anonymous"
                                    srcSet={`${image.thumbnail.uri}`}
                                    src={`${image.thumbnail.uri}`}
                                    style={{ borderRadius: 5 }}
                                    alt="BeautifulQRs" loading="lazy" onClick={ (e: any) => { setImageReceived(true); store.getBase64FromSrcWithCors(e.target.getAttribute('src')) }}/>
                                </ImageListItem>))
                            }
                            </ImageList>
                        </Box>
                    </Grid>
                }
                <Grid item xs={12} sm={12} md={3} >
                    <Box sx={{ paddingTop: isTab ? "2%" : "1%", backgroundColor: "#ffffff", borderTop: isTab ? 1 : 0, borderColor: "#b6b6b6", borderTopLeftRadius: isTab ? 10 : 0, borderTopRightRadius: isTab ? 10 : 0, borderBottomLeftRadius: (!showGuidelines || isTab) ? 0 : 5, borderBottomRightRadius: (!showGuidelines || isTab) ? 0 : 5, boxShadow: isTab ? 0 : 1 }}>
                        <Box onClick={() => { setShowGuidelines(!showGuidelines) }} sx={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                            <Typography variant={"h5"} flexGrow={1}>
                                Image Guidelines For Amazing QRs
                            </Typography>
                            {!isTab &&
                            <Box>
                                {showGuidelines ? <ExpandLess sx={{ flexShrink: 1 }}/> : <ExpandMore sx = {{ flexShrink: 1 }}/>}
                            </Box>
                            }
                        </Box>
                        <Collapse in={showGuidelines} timeout="auto" unmountOnExit>
                            <Typography textAlign={"left"} variant="h6" marginLeft={1} marginTop={1} marginBottom={-1}>
                                General Instructions
                            </Typography>
                            <Typography textAlign={"left"} variant="subtitle1">
                            <ul>
                                <li>Images with many objects would give suboptimal results</li>
                                <li>Minimal text and human faces give better QRs</li>
                            </ul>
                            </Typography>
                            <Typography textAlign={"left"} variant="h6" marginLeft={1}>
                                For brand logos
                            </Typography>
                            <Typography textAlign={"left"} variant="subtitle1" marginLeft={3}>
                                Ideal logo length/width should be 50% of the total image length width of image uploaded. (This minimises dots on the main logo)
                            </Typography>
                            <Typography textAlign={"left"} variant="h6" marginLeft={1} marginTop={1}>
                                For profile pictures
                            </Typography>
                            <Typography textAlign={"left"} variant="subtitle1" marginLeft={3} marginBottom={2}>
                                Add sufficient background to your picture (to avoid dots on your face).
                            </Typography>
                            <Typography textAlign={"left"} variant="h6" marginLeft={1} marginTop={1}>
                                For text
                            </Typography>
                            <Typography textAlign={"left"} variant="subtitle1" marginLeft={3} marginBottom={2}>
                                Avoid large amounts of text with thin cursive writing.
                            </Typography>
                        </Collapse>
                    </Box>
                </Grid>
            </Grid>
            </Box>
            }
        </Box>
        )
    }
)