export type HttpHeaders = Readonly<Record<string, string>>

export interface RequestConfig {
  headers: HttpHeaders
}

export enum HttpMethod {
  DELETE = 'DELETE',
  GET = 'GET',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT'
}

export interface RequestOptions {
  // if false then alert won't be triggered after error
  errorAlert?: boolean

  // object of additional config properties for fetch
  httpConfig?: any

  // if truthy returns the raw response object instead of just the data
  rawResponse?: boolean
}
