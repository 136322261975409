import Container from "@mui/material/Container";
import { observer } from "mobx-react";
import React, { useContext, type FC } from "react";
import { type Image2QRStore } from "src/store/Image2QRStore";
import AspectRatioBox from "./aspectRatioBox";

import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { BallTriangle } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { isTabContext } from "src/contexts/rootContext";

interface IImageQRContainerProps {
    store: Image2QRStore;
    setLoader: any;
  }

export const ImageQRContainer:FC<IImageQRContainerProps> = observer(({ store, setLoader }:IImageQRContainerProps) => {
    // const onDownload = () => {
    //     runInAction(() => {
    //         const a = document.createElement("a"); // Create <a>
    //         a.href = "data:image/jpeg;base64," + store.base64EncodedQR; // Image Base64 Goes here
    //         a.download = "BeautifulQR.jpeg"; // File name Here
    //         console.log(a.href);
    //         a.click();
    //     })
    //   };
    const navigate = useNavigate();
    const handleNextClick = async () => {
        window.scrollTo(0, 0);
        setLoader(true);
        store.setActiveRequest(0);
        await store.updateQRInfo();
        navigate('/qrgenworkflow/3');
        window.scrollTo(0, 0);
        setLoader(false);
    };
    const isTab = useContext(isTabContext);
    return (
        <Container sx={{ paddingTop: 2, paddingBottom: 2 }}>
        <Container sx={{ width: isTab ? "100%" : 7 / 8, boxShadow: 1, marginBottom: 2, borderRadius: "3%" }}>
            <AspectRatioBox ratio={1}>
            {
                store.base64EncodedQR !== "" &&
                <Box sx={{ position: 'relative' }}>
                    <img src={`data:image/jpeg;base64,${store.base64EncodedQR}`} width={"100%"}/>
                    {
                        store.activeImageRequest > 0 &&
                        <Box
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            bgcolor: 'rgba(255, 255, 255, 0.5)',
                            color: 'white'
                        }}
                        >
                        </Box>
                    }
                </Box>
            }
            {
                store.base64EncodedQR === "" &&
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Typography variant={"h6"} sx={{ marginTop: 2, marginBottom: isTab ? "4%" : 10 }}>
                Your QR will appear here 😊
                </Typography>
                <Box width={"50%"} maxWidth={300}>
                <BallTriangle
                    height={"100%"}
                    width={"100%"}
                    radius={"8%"}
                    color="#0099aa"
                    visible={true}
                    />
                </Box>
                </Box>
            }
            </AspectRatioBox>
        </Container>
        {
            store.base64EncodedQR !== "" &&
            <Button
            variant="outlined"
            disabled={store.activeImageRequest > 0}
            color="secondary"
            onClick={() => { void handleNextClick(); }}
            >
            Next
            </Button>
        }
        </Container>
    )
  })