import { Box, Button, Modal, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React, { type FC } from "react";
import { useNavigate } from "react-router-dom";
import { type RootStore } from "src/store/RootStore";

interface ILimitReachedPopupProps {
    store: RootStore;
}

export const LimitReachedPopup:FC<ILimitReachedPopupProps> = observer(({ store }:ILimitReachedPopupProps) => {
    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "85%",
        maxWidth: 800,
        bgcolor: 'background.paper',
        border: '1px solid #bbbbbb',
        borderRadius: 2,
        outline: 0,
        p: 4
    };
    const handleClose = () => { store.showLimitReachedModal = false; store.limitReachedModalMessage = null; };
    const handleContactUs = () => {
        navigate('/contactUs?page=2');
        store.showLimitReachedModal = false;
        store.limitReachedModalMessage = null;
        window.scrollTo(0, 0);
    };
    const navigate = useNavigate();
    return (
        <Box sx={{ outline: 'none' }}>
        <Modal open={store.showLimitReachedModal} onClose={handleClose}>
            <Box sx={modalStyle} textAlign={"center"} display="flex" flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                <Typography variant="h6" paddingBottom={1}>
                    {store.limitReachedModalMessage ?? `You have reached the maximum number of active QRs - ${String(store.customerQRInfo.noOfActiveQR ?? '0')} / ${String(store.customerQRInfo.maxNoOfActiveQR ?? '3')}`}
                </Typography>
                <Typography variant="h6" paddingBottom={1}>
                    {`Contact us to create more`}
                </Typography>
                <Box display={"flex"}>
                    <Button color="info" variant="contained" onClick={handleContactUs} sx={{ margin: 1 }}>
                        <Typography variant="body1">
                            Contact Us
                        </Typography>
                    </Button>
                    <Button color="error" variant="contained" onClick={handleClose} sx={{ margin: 1 }}>
                    <Typography variant="body1">
                        Close
                    </Typography>
                    </Button>
                </Box>
            </Box>
        </Modal>
        </Box>
    );
})