import { Close, Login, Person } from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Button, ButtonBase, Drawer, IconButton, Menu, MenuItem, Slide, Typography, useScrollTrigger } from "@mui/material";
import { observer } from "mobx-react";
import React, { useContext, useState, type FC } from 'react';
import { useNavigate } from 'react-router-dom';
// import { isMobileContext } from "src/contexts/rootContext";
import { isMobileContext, isTabContext } from "src/contexts/rootContext";
import { type RootStore } from 'src/store/RootStore';
import { getCognitoIdToken } from "src/utils/tokenUtils";
import logo from "../images/logo.svg";
import logoOnly from "../images/logoOnly.svg";

interface IMastheadProps {
    store: RootStore;
}

interface Props {
    isMobile: boolean;
    children: React.ReactElement;
}

function HideOnScroll (props: Props) {
    const { isMobile, children } = props;
    const trigger = useScrollTrigger();
    if (isMobile) {
        return (
            <Slide appear={false} direction="down" in={!trigger}>
            {children}
            </Slide>
        );
    } else {
        return (
            children
        );
    }
}

export const Masthead:FC<IMastheadProps> = observer(({ store }:IMastheadProps) => {
    // const classes = useStyles();
    const navigate = useNavigate();

    const handleHomeClick = () => {
        navigate('/');
        window.scrollTo(0, 0);
    };
    const handleDrawerHomeClick = () => {
        navigate('/');
        window.scrollTo(0, 0);
        setIsDrawer(false);
    };
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    console.log(store.isLoggedIn)
    console.log("hostname ", window.location.hostname)

    const handleLoginClick = () => {
        window.location.href = store.stageConfig.loginURLBase + store.stageConfig.webUrl;
    };

    const handleQRManagementClick = () => {
        if (getCognitoIdToken()) {
            navigate('/managementPortal');
            window.scrollTo(0, 0);
        }
    };

    const handleDrawerQRManagementClick = () => {
        if (getCognitoIdToken()) {
            navigate('/managementPortal');
            window.scrollTo(0, 0);
            setIsDrawer(false);
        }
    };

    const handleContactUsClick = () => {
        navigate('/contactUs?page=2');
        window.scrollTo(0, 0);
    };

    const handleDrawerContactUsClick = () => {
        navigate('/contactUs?page=2');
        window.scrollTo(0, 0);
        setIsDrawer(false);
    };

    const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogoutClick = () => {
        localStorage.removeItem("qrstore_id_token");
        localStorage.removeItem("qrstore_access_token");
        store.isLoggedIn = false;
        window.location.href = store.stageConfig.logoutURLBase + store.stageConfig.webUrl;
        setAnchorEl(null);
    };

    const isMobile = useContext(isMobileContext);
    const isTab = useContext(isTabContext);
    const [isDrawer, setIsDrawer] = useState<boolean>(false)

    return (
        <HideOnScroll isMobile={isMobile}>
        <AppBar position="sticky" color = {"primary"} elevation={1}>
            <Box display={"flex"} padding={1} paddingBottom={isMobile ? 0 : 1} justifyContent={"space-between"} alignItems={"space-between"} width={"100%"}>
                <img src={isMobile ? logoOnly : logo} onClick={handleHomeClick} width={isMobile ? 60 : 240}/>
                { !isTab &&
                    <Box marginTop={1}>
                        <Button color="inherit" onClick={handleHomeClick} sx={{ marginRight: 5 }}>
                            <Typography variant="subtitle1" >
                                Home
                            </Typography>
                        </Button>
                        {
                            store.isLoggedIn &&
                            <Button color="inherit" onClick={handleQRManagementClick} sx={{ marginRight: 5 }}>
                                <Typography variant="subtitle1" >
                                    QR Management
                                </Typography>
                            </Button>
                        }
                        <Button color="inherit" onClick={handleContactUsClick} sx={{ marginRight: 15 }}>
                            <Typography variant="subtitle1" >
                                Contact Us
                            </Typography>
                        </Button>
                    </Box>
                }
                <Box display={"flex"}>
                {
                    !store.isLoggedIn &&
                        <Button color="inherit" onClick={handleLoginClick} sx={{ marginTop: 0, marginRight: isTab ? 2 : 5 }}>
                            <Box display={"flex"} flexDirection={"column"} justifyContent={"start"} alignItems={"center"}>
                            <Login fontSize="medium"></Login>
                            <Typography variant="body2" >
                                LOGIN
                            </Typography>
                            </Box>
                        </Button>
                }
                {
                    store.isLoggedIn &&
                    <>
                        <IconButton
                        onClick={handleToggle}
                        sx={{ marginTop: 0, marginRight: isTab ? 2 : 5 }}
                        >
                            <Person/>
                        </IconButton>
                        <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button'
                        }}
                        >
                        <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
                        </Menu>
                    </>
                }
                {
                    isTab &&
                    <Box>
                        <Box>
                        <IconButton
                        onClick={() => { setIsDrawer(true) }}
                        sx={{ marginTop: 0 }}
                        >
                            <MenuIcon fontSize="large"/>
                        </IconButton>
                        </Box>
                        <Drawer
                            open={isDrawer}
                            anchor="right"
                            onClose={ () => { setIsDrawer(false) }}
                            PaperProps={{
                                sx: { width: isMobile ? "100%" : 400, backgroundColor: "#c9ffff" }
                              }}
                        >
                            <Box display={"flex"} flexDirection={"column"} justifyContent={"right"} sx={{ width: "100%" }} >
                                <Box display={"flex"} justifyContent={"right"} marginBottom={5} >
                                    <IconButton
                                    onClick={() => { setIsDrawer(false) }}
                                    sx={{ marginTop: 2, alignSelf: "right" }}
                                    >
                                        <Close fontSize="large" />
                                    </IconButton>
                                </Box>
                                <Box display={"flex"} justifyContent={"center"} margin={2}>
                                    <ButtonBase onClick={handleDrawerHomeClick} sx={{ textAlign: "left", width: isMobile ? "90%" : "80%", backgroundColor: "#a9ffff", borderRadius: 10 }}>
                                        <Typography variant="h5" textAlign={"left"} margin={2} marginLeft={5} color={"#000000"} width={"100%"}>
                                            Home
                                        </Typography>
                                    </ButtonBase>
                                </Box>
                                {
                                store.isLoggedIn &&
                                <Box display={"flex"} justifyContent={"center"} margin={2}>
                                    <ButtonBase onClick={handleDrawerQRManagementClick} sx={{ textAlign: "left", width: isMobile ? "90%" : "80%", backgroundColor: "#a9ffff", borderRadius: 10 }}>
                                        <Typography variant="h5" textAlign={"left"} margin={2} marginLeft={5} color={"#000000"} width={"100%"}>
                                            QR Management
                                        </Typography>
                                    </ButtonBase>
                                </Box>
                                }
                                <Box display={"flex"} justifyContent={"center"} margin={2}>
                                    <ButtonBase onClick={handleDrawerContactUsClick} sx={{ textAlign: "left", width: isMobile ? "90%" : "80%", backgroundColor: "#a9ffff", borderRadius: 10 }}>
                                        <Typography variant="h5" textAlign={"left"} margin={2} marginLeft={5} color={"#000000"} width={"100%"}>
                                            Contact Us
                                        </Typography>
                                    </ButtonBase>
                                </Box>
                            </Box>
                        </Drawer>
                    </Box>
                }
                </Box>
            </Box>
        </AppBar>
        </HideOnScroll>
    );
});
