import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import InputBase from '@mui/material/InputBase'
import Typography from '@mui/material/Typography'
import React, { useContext, useState } from 'react'
import { subscribeToNewsletter } from 'src/api/image2QR'
import { isMobileContext } from 'src/contexts/rootContext'

export default function HomeNewsLetter () {
  const [email, setEmail] = useState<string>("");
  const [showEmailError, setShowEmailError] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const validateEmail = (email: string) => String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  const onSubscribe = () => {
    if (validateEmail(email)) {
      subscribeToNewsletter(email).then(() => {
        setShowSuccess(true);
        setTimeout(() => { setShowSuccess(false) }, 10000)
      }).catch((e) => {
        console.log("Error occured ", e)
      });
    } else {
      setShowEmailError(true);
    }
  }
  const isMobile = useContext(isMobileContext);
  return (
    <Box sx={{ backgroundColor: "#ffe5e5", borderTop: 1, py: { xs: 8, md: 10 } }}>
      <Container>
        <Box
          sx={{
            backgroundColor: 'secondary.main',
            borderRadius: 10,
            py: { xs: 3, md: 8 },
            px: { xs: 3, md: 8 },
            textAlign: 'center',
            maxWidth: 1000,
            margin: "auto"
          }}
        >
          <Typography variant="h1" component="h2" sx={{ mb: 1, fontSize: { xs: 32, md: 42 } }}>
            Subscribe to Our Newsletter
          </Typography>
          <Typography sx={{ mb: 2 }}>Like what we do? Subscribe to stay updated with our latest releases.</Typography>
          <Box sx={{ width: { xs: '100%', md: 560 }, mx: 'auto' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: 'space-around',
                width: "100%"
              }}
            >
              <Box sx={{ mr: { xs: 0, md: 3 }, mb: { xs: 2, md: 0 }, width: "100%" }}>
              <InputBase
                sx={{
                  backgroundColor: 'background.paper',
                  borderRadius: 3,
                  width: '100%',
                  height: 48,
                  px: 2
                }}
                placeholder="Enter your Email Address"
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setEmail(e.target.value); }}
              />
              {
                isMobile && showEmailError &&
                <Typography variant='body1' textAlign={"left"} color={"red"}>
                  Enter a valid email ID
                </Typography>
              }
              {
                isMobile && showSuccess &&
                <Typography variant='body1' textAlign={"left"} color={"green"}>
                  Thank You For Subscribing
                </Typography>
              }
              </Box>
              <Box>
                <Button variant="contained" color="primary" onClick={onSubscribe}>
                  Subscribe
                </Button>
              </Box>
            </Box>
            {
              !isMobile && showEmailError &&
              <Typography variant='body1' textAlign={"left"} color={"red"}>
                Enter a valid email ID
              </Typography>
            }
            {
              !isMobile && showSuccess &&
              <Typography variant='body1' textAlign={"left"} color={"green"}>
                Thank You For Subscribing
              </Typography>
            }
          </Box>
        </Box>
      </Container>
    </Box>
  )
}