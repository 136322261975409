import { HttpMethod, type RequestOptions } from './types/requestConfig.type'

import customAxios from './interceptor'

/*
  NOTE: This is the skeleton ,things will change after the Auth mechanism is finalized
*/

export interface RequestInterface {
  baseURL: any,
  userType: string
}

export class ApiClientBase {
  private readonly client

  constructor (requestBase: RequestInterface) {
    // This condition might change in future
    this.client = customAxios(requestBase)
    this.client.defaults.timeout = 20000

    this.client.defaults.headers.common = {
      accept: 'application/json, text/plain, */*'
    }
    this.client.defaults.headers.post = {}
    this.client.defaults.headers.put = {}
  }

  public async request (
    // description: string = '',
    method: HttpMethod,
    url?: string,
    data: Record<string, any> = {},
    options: RequestOptions = {}
  ): Promise<any> {
    if (!this.isValidHttpMethod(method)) {
      throw new Error('Unsupported request type.')
    }

    // const headers = {
    //   anything
    // };

    let requestObj = {
      method,
      // headers,
      data,
      ...options.httpConfig
    }
    // do not send empty data objects
    if ((Object.keys(data).length === 0) || method === HttpMethod.GET) {
      requestObj = {
        method,
        // headers,
        undefined,
        ...options.httpConfig
      }
    }

    let response
    try {
      response = await this.client(url ?? '', requestObj)
    } catch (e) {
      return await Promise.reject(e)
    }

    if (options.rawResponse !== undefined && options.rawResponse) {
      return await Promise.resolve(response)
    } else {
      // some formatting on response
    }

    return await Promise.resolve(response)
  }

  // public async upload(
  //   url: string,
  //   file: File,
  //   data?: { [key: string]: any },
  //   options?: RequestOptions
  // ): Promise<any> {}

  restApi = (axios: {
    get: (arg0: any, arg1: Record<string, unknown>) => any
    post: (arg0: any, arg1: any, arg2: Record<string, unknown>) => any
    put: (arg0: any, arg1: any, arg2: Record<string, unknown>) => any
    patch: (arg0: any, arg1: any, arg2: Record<string, unknown>) => any
    delete: (arg0: any, arg1: Record<string, unknown>) => any
  }) => ({
    get: (url: any, config = {}) => axios.get(url, config),
    post: (url: any, body: any, config = {}) => axios.post(url, body, config),
    put: (url: any, body: any, config = {}) => axios.put(url, body, config),
    patch: (url: any, body: any, config = {}) => axios.patch(url, body, config),
    delete: (url: any, config = {}) => axios.delete(url, config)
  })

  public api (): any {
    return this.restApi(this.client)
  }

  public isValidHttpMethod (value: HttpMethod): boolean {
    return Object.values(HttpMethod).includes(value)
  }
}
