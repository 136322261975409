import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Box, Button, Collapse, Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup, Slider, Stack,
  ToggleButton, ToggleButtonGroup, Typography
} from "@mui/material";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState, type FC } from 'react';
import { type Crop, type IArea, type IRectangle } from '../types/CropSelectorTypes';
import { centerCrop, convertToPercentArea, convertToPercentCrop, makeAspectCrop } from '../utils/cropSelectorUtils';
import { CustomCropperAndSelector } from './CustomCropperAndSelector/CustomCropperAndSelector';

import { getQRFromImage } from "../api/image2QR";
import "./CustomCropperAndSelector/CustomCropperAndSelector.scss";
// import { getCroppedImg, convertToBase64 } from "../utils/imageUtils";
import UndoIcon from '@mui/icons-material/SettingsBackupRestore';
import { runInAction } from "mobx";
import { type Image2QRStore } from 'src/store/Image2QRStore';
import { type ImageTemplate } from 'src/types/QRInfoTypes';

// const getColor = (props:any) => {
//   if (props.isDragAccept) {
//     return '#00e676';
//   }
//   if (props.isDragReject) {
//     return '#ff1744';
//   }
//   if (props.isFocused) {
//     return '#2196f3';
//   }
//   return '#eeeeee';
// };f</Dropzone>

// const Container = styled.div`
//   align-items: center;
//   padding: '50px';
//   border-width: 2px;
//   border-radius: 10px;
//   border-color: ${(props) => getColor(props)};
//   border-style: dashed;
//   background-color: #fafafa;
//   color: black;
//   font-weight: bold;
//   font-size: 1.4rem;
//   outline: none;
//   transition: border 0.24s ease-in-out;
//   height:100%,
//   width: 100%
// `;

interface ITemplateCreatorProps {
  store: Image2QRStore;
}

export const TemplateCreator:FC<ITemplateCreatorProps> = observer(({ store }:ITemplateCreatorProps) => {
  const image = useRef<HTMLImageElement>(null);
  // console.log(window.innerWidth);
  const [crop, setCrop] = useState<Crop>({
    unit: '%', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50
  })
  const [ssValue, setSSValue] = useState(1);
  const [eccValue, setEccValue] = useState("1");
  const [dotSizeValue, setDotSizeValue] = useState("1");
  const [blendValue, setBlendValue] = useState("1");
  const [version, setVersion] = useState("5");
  const [clearDotsValue, setClearDotsValue] = useState(3);
  const [disableCrop, setDisableCrop] = useState<boolean>(false);
  const [areas, setAreas] = useState<IArea[]>([]);
  const [initCrop, setInitCrop] = useState(0);
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);
  const [showAdvancedOptions, setShowAdvancedOptions] = React.useState(true);
  const eccVals = ["1", "1", "1", "3"];
  const dotSizeVals = ["0", "1", "2", "3"];
  const blendVals = ["1", "1", "2", "2"];
  const versionVals = ["5", "5", "5", "5"];
  const clearDotVals = [2, 2, 3, 3];

  console.log("areas", areas);
  console.log("crop", crop);
  // autorun(() => { console.log("base64Image", store.base64EncodedImage) });

  useEffect(() => {
    const width = image.current?.width ?? 200;
    const height = image.current?.height ?? 200;
    if (height > width) {
      const crop = centerCrop(
        makeAspectCrop(
          {
            unit: '%',
            width: 95
          },
          1,
          width,
          height
        ),
        width,
        height
      )
      setCrop(crop)
    } else {
      const crop = centerCrop(
        makeAspectCrop(
          {
            unit: '%',
            height: 95
          },
          1,
          width,
          height
        ),
        width,
        height
      )
      setCrop(crop)
    }
    console.log("In Crop Vals " + JSON.stringify(crop));
  }, [isImageLoaded])

  // function onImageLoad (e:any) {
  //   setDisableCrop(false);
  //   const { width, height } = e.currentTarget;
  //   if (height > width) {
  //     const crop = centerCrop(
  //       makeAspectCrop(
  //         {
  //           unit: '%',
  //           width: 75
  //         },
  //         1,
  //         width,
  //         height
  //       ),
  //       width,
  //       height
  //     )
  //     setCrop(crop)
  //   } else {
  //     const crop = centerCrop(
  //       makeAspectCrop(
  //         {
  //           unit: '%',
  //           height: 75
  //         },
  //         1,
  //         width,
  //         height
  //       ),
  //       width,
  //       height
  //     )
  //     setCrop(crop)
  //   }
  // }

  const createQR = () => {
    // getCroppedImg(image.current, crop).then((cropImageBlob) => {
    //   convertToBase64(cropImageBlob).then((base64Image) => {
    const percentCropTemp: IRectangle = convertToPercentCrop(crop, image.current?.width ?? 200, image.current?.height ?? 200)
    const percentCrop: IRectangle = {
        x: percentCropTemp.x / 100,
        y: percentCropTemp.y / 100,
        width: percentCropTemp.width / 100,
        height: percentCropTemp.height / 100
      }
    const percentAreas: IRectangle[] = [];
    areas.forEach((val: IRectangle) => {
      const percentArea = convertToPercentArea(val, image.current?.width ?? 200, image.current?.height ?? 200);
      percentAreas.push({
        x: Math.max(percentArea.x / 100, percentCrop.x + 0.0003),
        y: Math.max(percentArea.y / 100, percentCrop.y + 0.0003),
        width: Math.min(percentArea.width / 100, (percentCrop.x + percentCrop.width - Math.max(percentArea.x / 100, percentCrop.x + 0.0003)) - 0.0006),
        height: Math.min(percentArea.height / 100, (percentCrop.y + percentCrop.height - Math.max(percentArea.y / 100, percentCrop.y + 0.0003)) - 0.0006)
      });
    });
    const imageTemplate: ImageTemplate = {
      crop: percentCrop,
      areas: percentAreas,
      ecc: parseInt(eccValue),
      dotSize: parseInt(dotSizeValue),
      version: parseInt(version),
      blend: parseInt(blendValue),
      clearDot: clearDotsValue
    }
    runInAction(() => {
      console.log(store.qrInfo.qrID);
      store.qrInfo.imageTemplate = imageTemplate;
      store.incrementActiveRequest();
      getQRFromImage(store.qrInfo).then((response) => {
          store.base64EncodedQR = response.data.base64EncodedQR;
          console.log("dataReceived ", store.base64EncodedQR);
          store.decrementActiveRequest();
      }, (reason) => { console.log(reason); store.decrementActiveRequest(); })
    });
    //   }, (reason) => { console.log(reason) })
    // }, (reason) => { console.log(reason) });
  }

  const handleAdvancedOptionsClick = () => {
    setShowAdvancedOptions(!showAdvancedOptions);
  };

  useEffect(() => {
    if (initCrop) {
      createQR();
    } else {
      setInitCrop(1);
    }
  }, [eccValue, dotSizeValue, blendValue, clearDotsValue, version]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (initCrop) {
        createQR();
      } else {
        setInitCrop(1);
      }
    }, 500)

    return () => { clearTimeout(delayDebounceFn); }
  }, [crop, areas])

  useEffect(() => {
    setEccValue(eccVals[ssValue]);
    setDotSizeValue(dotSizeVals[ssValue]);
    setBlendValue(blendVals[ssValue]);
    setVersion(versionVals[ssValue]);
    setClearDotsValue(clearDotVals[ssValue]);
  }, [ssValue])

  return (
    <Box sx={{ width: "100%", marginTop: 2, marginBottom: 2 }}>
      <Container sx={{ paddingTop: 3 }} disableGutters>
        <CustomCropperAndSelector style={{ boxShadow: "0px 0px 3px 1% black", maxHeight: "60vh" }}
                    crop={crop}
                    aspect={1}
                    disabled={disableCrop}
                    areas={areas}
                    maxAreas={5}
                    onChange={(crop, area) => { setCrop(crop); setAreas(area); }}>
                    <img
                    src={store.base64EncodedImage}
                    ref={image}
                    style={{ margin: "auto" }}
                    onLoad={() => { setIsImageLoaded(true) }}/>
        </CustomCropperAndSelector>
      </Container>
      {/* store.rootStore.stageConfig.imageURLBase + (store.qrInfo.imageURL ?? "") */}
      {/* <Box paddingX={3}>
        <TextField
          fullWidth
          inputRef={qrURLRef}
          placeholder="https://theqrstore.in"
          id="uriText"
          label="Enter URL"
          variant="outlined"
          color="primary"
          margin="normal"
          onChange={() => { setUrlVal(qrURLRef.current ? qrURLRef.current.value ?? '' : ''); }}
        />
      </Box> */}
      <Container disableGutters>
        <FormControl>
          <Typography>Scanning Strength</Typography>
          <RadioGroup
            name="ss-radio-buttons-group"
            row = {true}
            value={ssValue}
            onChange={(_event, value) => { setSSValue(parseInt(value)); }}
          >
            <FormControlLabel value="0" control={<Radio />} label="1" />
            <FormControlLabel value="1" control={<Radio />} label="2" />
            <FormControlLabel value="2" control={<Radio />} label="3" />
            <FormControlLabel value="3" control={<Radio />} label="4" />
          </RadioGroup>
        </FormControl>
      </Container>
      <Container onClick={handleAdvancedOptionsClick} disableGutters>
        <Box>
          <Typography>Advanced Options</Typography>
          {showAdvancedOptions ? <ExpandMore /> : <ExpandLess />}
        </Box>
      </Container>
      <Collapse in={!showAdvancedOptions} timeout="auto" unmountOnExit>
      <Stack spacing={2} marginTop={2}>
        <Box display={"flex"} justifyContent={"center"}>
          <ToggleButtonGroup
            value={disableCrop}
            exclusive
            onChange={(_event, value) => { setDisableCrop(value) }}
          >
            <ToggleButton value={false} disabled={!disableCrop} >Crop Image</ToggleButton>
            <ToggleButton value={true} disabled={disableCrop}>Select Area</ToggleButton>
          </ToggleButtonGroup>
          <Button disabled={!disableCrop} variant={"contained"} onClick={() => { setAreas(areas.slice(0, -1)) }} sx={{ marginLeft: 2 }}>
            <UndoIcon/>
          </Button>
        </Box>
        <Container disableGutters>
          <FormControl>
            <Typography>ECC</Typography>
            <RadioGroup
              name="ecc-radio-buttons-group"
              row = {true}
              value={eccValue}
              onChange={(_event, value) => { setEccValue(value); }}
            >
              <FormControlLabel value="0" control={<Radio />} label="L" />
              <FormControlLabel value="1" control={<Radio />} label="M" />
              <FormControlLabel value="2" control={<Radio />} label="Q" />
              <FormControlLabel value="3" control={<Radio />} label="H" />
            </RadioGroup>
          </FormControl>
        </Container>
        <Container disableGutters>
          <FormControl>
            <Typography>Dot Size</Typography>
            <RadioGroup
              name="dotSize-radio-buttons-group"
              row = {true}
              value={dotSizeValue}
              onChange={(_event, value) => { setDotSizeValue(value); }}
            >
              <FormControlLabel value="0" control={<Radio />} label="S" />
              <FormControlLabel value="1" control={<Radio />} label="M" />
              <FormControlLabel value="2" control={<Radio />} label="L" />
              <FormControlLabel value="3" control={<Radio />} label="XL" />
            </RadioGroup>
          </FormControl>
        </Container>
        <Container disableGutters>
          <FormControl>
            <Typography>Blend</Typography>
            <RadioGroup
              name="blend-radio-buttons-group"
              row = {true}
              value={blendValue}
              onChange={(_event, value) => { setBlendValue(value); }}
            >
              <FormControlLabel value="0" control={<Radio />} label="1" />
              <FormControlLabel value="1" control={<Radio />} label="2" />
              <FormControlLabel value="2" control={<Radio />} label="3" />
              <FormControlLabel value="3" control={<Radio />} label="4" />
            </RadioGroup>
          </FormControl>
        </Container>
        <Container disableGutters>
          <FormControl>
            <Typography>Version</Typography>
            <RadioGroup
              name="version-radio-buttons-group"
              row = {true}
              value={version}
              onChange={(_event, value) => { setVersion(value); }}
            >
              <FormControlLabel value="5" control={<Radio />} label="5" />
              <FormControlLabel value="6" control={<Radio />} label="6" />
            </RadioGroup>
          </FormControl>
        </Container>
        <Container disableGutters>
          <Stack>
            <FormLabel id="clearDots-slider-group-label">Clear Dots</FormLabel>
            <Container>
              <Slider
                step={1}
                marks
                min={0}
                max={6}
                aria-label="Always visible"
                sx={{ width: 3 / 4 }}
                value={clearDotsValue}
                onChange={(_event, value) => { setClearDotsValue(typeof (value) === "number" ? value : 0); }}
              />
            </Container>
          </Stack>
        </Container>
      </Stack>
      </Collapse>
    </Box>
  );
});