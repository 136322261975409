import { action, makeAutoObservable, runInAction } from "mobx";
import { getQRInfoAPI, initializeQRInfoAPI, postQRInfoForCustomer, saveImageQRAPI } from "src/api/image2QR";
import { WEB_URL_BASE } from "src/constants/RootConstants";
import { type QRInfo } from "src/types/QRInfoTypes";
import { convertToBase64 } from "src/utils/imageUtils";
import { type RootStore } from "./RootStore";

export class Image2QRStore {
  base64EncodedQR: string;
  base64EncodedImage: string;
  imageFileName: string;
  qrInfo: QRInfo;
  activeImageRequest: number;
  rootStore: RootStore;

  imageFileUpload = (fileUploaded:any) => {
    const reader = new FileReader();

    reader.onload = () => {
        const url = reader.result as string;

        const image = new Image();

        image.onload = () => {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            const { width, height } = image;
            canvas.width = width;
            canvas.height = height;
            context?.drawImage(image, 0, 0, width, height);

            // export base64
            this.setBase64EncodedImage(canvas.toDataURL("image/jpeg"));
            this.imageFileName = fileUploaded.name
        };
        image.src = url;
    }

    reader.readAsDataURL(fileUploaded);
    // setImageURL(URL.createObjectURL(fileUploaded));
    // if (fileUploaded != null) {
    //   void convertToBase64(fileUploaded).then(action((value) => {
    //     this.setBase64EncodedImage(value);
    //     this.imageFileName = fileUploaded.name;
    //   }))
    // }
  }

  getBase64FromSrcWithCors = (imgSrc: string) => {
    void fetch(imgSrc, { mode: 'cors' }).then(async (data) => await data.blob() ).then(async (imageBlob) =>
        await convertToBase64(imageBlob)
    ).then(action((value) => {
      this.setBase64EncodedImage(value);
      console.log(this.base64EncodedImage);
    }));
  }

  setBase64EncodedImage (base64EncodedImage: string) {
    this.base64EncodedImage = base64EncodedImage;
  }

  setActiveRequest (activeImageRequest: number) {
    this.activeImageRequest = activeImageRequest
  }

  incrementActiveRequest () {
    this.activeImageRequest++;
  }

  decrementActiveRequest () {
    if (this.activeImageRequest > 0) {
      this.activeImageRequest--;
    }
  }

  setWebsiteEndpoint (websiteEndpoint: string) {
    this.qrInfo.websiteEndpoint = websiteEndpoint
  }

  setQRName (qrName: string) {
    this.qrInfo.qrName = qrName
  }

  setQRDescription (qrDescription: string) {
    this.qrInfo.qrDescription = qrDescription
  }

  setQRInfo (qrInfo: QRInfo) {
    this.qrInfo = qrInfo;
  }

  async initializeQRInfo () {
    const qrData = await initializeQRInfoAPI(this.imageFileName, this.base64EncodedImage);
    try {
      runInAction(() => {
        this.qrInfo = qrData.data;
      }
    );
    } catch (e) {
      console.log("Error occured", e);
    }
  }

  async getQRInfo () {
    if (this.qrInfo.qrID !== "") {
      const qrData = await getQRInfoAPI(this.qrInfo.qrID);
      try {
        runInAction(() => {
          this.qrInfo = qrData.data;
        }
      );
      } catch (e) {
        console.log("Error occured", e);
      }
      }
  }

  async postQRInfo () {
    const qrData = await postQRInfoForCustomer(this.qrInfo);
    try {
      runInAction(() => {
        this.qrInfo = qrData.data;
      }
    );
    console.log(qrData.data);
    } catch (e) {
      console.log("Error occured", e);
    }
  }

  async updateQRInfo () {
    const qrData = await saveImageQRAPI(this.qrInfo);
    try {
      runInAction(() => {
        this.qrInfo = qrData.data.qrInfo;
        this.base64EncodedQR = qrData.data.base64EncodedQR;
      }
    );
    } catch (e) {
      console.log("Error occured", e);
    }
  }

  constructor (rootStore: RootStore) {
    // makeObservable(this, {
    //   base64EncodedImage: observable,
    //   base64EncodedQR: observable,
    //   imageFileUpload: _autoAction,
    //   handleImageClick: _autoAction
    // });
    makeAutoObservable(this);
    this.base64EncodedImage = "";
    this.base64EncodedQR = "";
    this.imageFileName = "";
    this.activeImageRequest = 0;
    this.rootStore = rootStore;
    this.qrInfo = {
      qrID: "",
      // imageURL: "",
      // qrEndpoint: "",
      // imageTemplate: {
      //   crop: {
      //     x: 0,
      //     y: 0,
      //     width: 0,
      //     height: 0
      //   },
      //   areas: [],
      //   ecc: 0,
      //   dotSize: 0,
      //   clearDot: 0,
      //   blend: 0,
      //   version: 0
      // },
      websiteEndpoint: WEB_URL_BASE
    };
  }
}
