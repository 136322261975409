import qs from "qs";
import { getCurrentStageConfig } from "src/api/stageConfig";

function isKey<T extends object> (
    x: T,
    k: PropertyKey
  ): k is keyof T {
    return k in x;
  }

export function setTokensInLocalStorage () {
    const currentUrl = window.location.href;
    const [, params] = currentUrl.split('#');
    const parsedParams = qs.parse(params);
    Object.keys(parsedParams).forEach((key) => {
      if (isKey(parsedParams, key)) {
        const val = parsedParams[key];
        if (typeof val == "string") {
            localStorage.setItem('qrstore_' + key, val)
        }
      }
    })
}

export function getCognitoIdToken () {
  const idToken = localStorage.getItem("qrstore_id_token");
  if (idToken == null) {
    window.location.href = getCurrentStageConfig().loginURLBase + window.location.href.split('?')[0];
  }
  return idToken;
}

export function getCognitoAccessToken () {
  const accessToken = localStorage.getItem("qrstore_access_token");
  if (accessToken == null) {
    window.location.href = getCurrentStageConfig().loginURLBase + window.location.href.split('?')[0];
    console.log(window.location.href);
  }
  return accessToken;
}
