import React, { type ReactNode } from 'react';
// import { makeStyles } from "@mui/material/styles";

// const useStyles = makeStyles(() => ({
//   root: { position: "relative" },
//   wrapper: {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     "& > *": { height: "100%", width: "100%" }
//   }
// }));

interface AspectRatioBoxProps {
  ratio: number,
  children?: ReactNode
}

const AspectRatioBox = ({ children, ratio = 1 }:AspectRatioBoxProps) =>
  // const classes = useStyles();
   (
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>{children}</div>
      <div style={{ paddingBottom: ((1 / ratio) * 100).toString() + "%" }} />
    </div>
  )
;

export default AspectRatioBox;